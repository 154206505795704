<template>
  <div class="wrapper blog-post">
    <div class="page-header page-header-small">
      <parallax
        class="page-header-image"
        style="background-image: url('img/home/utah.jpg')"
      ></parallax>
      <div class="content-center">
        <div class="row">
          <div class="col-md-8 ml-auto mr-auto text-center">
            <h2 class="title">Certifications</h2>
            <h4>Offered to UMC Members Only.</h4>
          </div>
        </div>
      </div>
    </div>
    <div class="section">
      <div class="container">
        <div class="row">
          <div class="col-md-10 ml-auto mr-auto">
            <h3 class="title">Certifications</h3>
            <p>Membership is open to all; you don’t need to be a Montessorian or a Montessori school. All you have to do is complete the one page application and agree to abide by the Code of Ethics and it’s free!</p>
            <p>These certifications are administered annually by the Utah Montessori Council. Click on the logos below to see a list of criteria a school is required to meet in order to receive that certification.</p>
            <p>The annual cost of certification is $25 each certification and payable to the UMC and a fee of $200/day plus expenses payable directly to the Certification Consultant. These logos are federally registered trademarks; when a school receives a certification, it also receives permission to publish the logo in their advertising for one school year.</p>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-md-3 ml-auto mr-auto">
            <card plain>
              <a
                slot="image"
                href="downloads/Certified-Safe-School.pdf"
                target="_blank"
              >
                <img
                  class="img-raised rounded"
                  src="img/certs/SafeSchool.gif"
                />
              </a>
            </card>
          </div>
          <div class="col-md-3 ml-auto mr-auto">
            <card plain>
              <a
                slot="image"
                href="downloads/Certified-Green-School.pdf"
                target="_blank"
              >
                <img
                  class="img-raised rounded"
                  src="img/certs/GreenSchool.gif"
                />
              </a>
            </card>
          </div>
          <div class="col-md-3 ml-auto mr-auto">
            <card plain>
              <a
                slot="image"
                href="downloads/Certified-Healthy-School.pdf"
                target="_blank"
              >
                <img
                  class="img-raised rounded"
                  src="img/certs/HealthySchool.gif"
                />
              </a>
            </card>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Card, Button, Comment, Badge, InfoSection, Checkbox } from '@/components';

export default {
  name: 'blog-post',
  bodyClass: 'blog-post',
  components: {
    Card,
    InfoSection,
    Badge,
    Comment,
    [Button.name]: Button,
    [Checkbox.name]: Checkbox
  },
  metaInfo: {
    title: 'Utah Montessori - Certifications',
    meta: [
      { name: 'description', content: 'Members can get certified.' }
    ]
  }
}
</script>
<style>
</style>
