<template>
  <div class="wrapper blog-post">
    <div class="page-header page-header-small">
      <parallax
        class="page-header-image"
        style="background-image: url('img/home/utah.jpg')"
      ></parallax>
      <div class="content-center">
        <div class="row">
          <div class="col-md-8 ml-auto mr-auto text-center">
            <h2 class="title">Call For Presenters</h2>
            <h4>Do you have a topic you'd like to present?</h4>
          </div>
        </div>
      </div>
    </div>
    <div class="section">
      <div class="container">
        <div class="row">
          <div class="col-md-10 ml-auto mr-auto">
            <h3 class="title">UMC Call for Presenters</h3>
            <p>
              We are looking forward to another wonderful workshop and
              conference. If you have a topic you’d like to present or know
              someone who might, please complete the
              <a
                href="https://forms.gle/59eM8d39qhn857nq7"
                target="_blank"
                class="btn btn-primary btn-round"
                ><strong>online form</strong></a
              >
              by <strong>May 15, 2024.</strong>
            </p>
            <p>If you have questions, please feel free to contact us.</p>
            <p>
              Duna Strachan, MEd<br />UMC Conference Coordinator<br />2083
              Equestrian Court<br />Park City UT 84060<br />435-659-1493<br />duna@soaringwings.org
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  Card,
  Button,
  Comment,
  Badge,
  InfoSection,
  Checkbox,
} from "@/components";

export default {
  name: "call-for-presenters",
  bodyClass: "call-for-presenters",
  data() {
    return {
      conference: "21st Annual Conference",
      confPlace: "Salt Lake Marriott University Park",
      confDate: "TBD",
      confCity: "Salt Lake City",
    };
  },
  components: {
    Card,
    InfoSection,
    Badge,
    Comment,
    [Button.name]: Button,
    [Checkbox.name]: Checkbox,
  },
  metaInfo: {
    title: "Utah Montessori - Call For Presenters",
    meta: [
      {
        name: "description",
        content:
          "If you have a topic you would like to present or know someone who might?",
      },
    ],
  },
};
</script>
<style>
.freebirdFormviewerViewCenteredContent {
  margin: 0 !important;
}
</style>
