<template>
  <div class="wrapper blog-post">
    <div class="page-header page-header-small">
      <parallax
        class="page-header-image"
        style="background-image: url('img/home/utah.jpg')"
      ></parallax>
      <div class="content-center">
        <div class="row">
          <div class="col-md-8 ml-auto mr-auto text-center">
            <h2 class="title">UMC Member</h2>
            <h4>Join the vision of the future!</h4>
          </div>
        </div>
      </div>
    </div>
    <div class="section">
      <div class="container">
        <div class="row">
          <div class="col-md-10 ml-auto mr-auto">
            <h3 class="title">Become a Member</h3>
            <p>
              Membership is open to all; you don’t need to be a Montessorian or
              a Montessori school. All you have to do is complete the one page
              application and agree to abide by the
              <a
                href="downloads/CodeofEthics.pdf"
                target="_blank"
                class="btn btn-primary btn-round"
                ><strong>Code of Ethics</strong></a
              >
              and it’s free!
            </p>
            <p>
              The UMC was founded in 2004 with the mission of creating a system
              of support for the Montessori schools of Utah. We organize
              professional conferences and workshops featuring nationally known
              presenters as well as our own local talent to assist faculty,
              staff and heads of schools in improving their methodology. In
              addition we keep our collective ears tuned to issues that may
              affect our schools. The current status of Montessori schools as
              self-regulating with regard to state daycare laws is in place
              because of the efforts of the UMC in general and Bruce King, UMC
              Treasurer, in particular. The UMC offers a school certification
              program in order to maintain this status.
            </p>
            <p>
              To become a member, please print out

              <a
                href="https://docs.google.com/forms/d/e/1FAIpQLScCIr1QBvoKSkzyQp-AYnAOkDN-dSDi-qTGMVltqHfSRP-tkQ/viewform?usp=sf_link"
                target="_blank"
                class="btn btn-primary btn-round"
                ><strong>Membership Application</strong></a
              >, complete and mail to: UMC, 2083 Equestrian Court, Park City,
              Utah 84060
            </p>
            <h3 class="title">Member Benefits</h3>
            <p>Membership Certificate</p>
            <p>Notification of Events In or Near Utah</p>
            <p>Eligibility for UMC Certification Programs</p>
            <p>Opportunity to vote on UMC issues</p>
            <p>
              Eligibility for directories, school surveys and other useful
              information
            </p>
            <p>Eligibility for consultations</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  Card,
  Button,
  Comment,
  Badge,
  InfoSection,
  Checkbox,
} from "@/components";

export default {
  name: "member",
  bodyClass: "member",
  components: {
    Card,
    InfoSection,
    Badge,
    Comment,
    [Button.name]: Button,
    [Checkbox.name]: Checkbox,
  },
  metaInfo: {
    title: "Utah Montessori - Become A Member",
    meta: [
      {
        name: "description",
        content: "How to become a member of Utah Montessori Council!",
      },
    ],
  },
};
</script>
<style></style>
