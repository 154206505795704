<template>
  <div class="wrapper fall-workshop">
    <div class="page-header page-header-small">
      <parallax
        class="page-header-image"
        style="background-image: url('img/workshop/future.jpg')"
      ></parallax>
      <div class="content-center">
        <div class="row">
          <div class="col-md-8 ml-auto mr-auto text-center">
            <h2 class="title">21st Annual UMC Conference</h2>
            <h4>
              <strong>
                January 26 & 27, 2024<br />The Marriott University Park<br />Salt
                Lake City</strong
              >
            </h4>
          </div>
        </div>
      </div>
    </div>
    <div class="section">
      <div class="container">
        <div class="row">
          <div class="col-md-8 ml-auto mr-auto">
            <h3 class="title">
              The Future of Smart:<br />Education for the 21st Century
            </h3>
            <hr />
            <div class="row">
              <div class="col-md-8 ml-auto mr-auto">
                <h4>Keynote Speaker<br>Dr. Ulcca Joshi Hansen</br></h4>
                <h6>Author of The Future of Smart</h6>
                <p>
                  Dr. Hansen‘s work includes adult/teacher preparation and
                  school-based improvement strategies, with an eye towards
                  incorporating cutting-edge research on human development, the
                  learning sciences, and best practices in helping adults
                  develop the skills they need to relate to conversations about
                  race, identity, equity and belonging.
                </p>
              </div>
              <div class="col-md-4 ml-auto mr-auto">
                <img src="img/ulcca.png" class="img img-raised" />
              </div>
            </div>
            <hr />
            <div class="col-md-12 ml-auto mr-auto">
              <h4><strong>Friday, January 26th</strong></h4>
              <p>
                <strong>1:00-3:00pm: </strong>Self-directed School Tour: Maria Montessori Academy
                <br />
                <strong>4:00-5:00pm:</strong> Check-in, Montessori Mall, 21st Centuary Dance Party: Marriott Atrium<br />
                <strong>5:00-6:00pm:</strong> Opening Ceremony by Nancy Lindeman: Marriott Ballroom
              </p>
            </div>
            <div class="col-md-12 ml-auto mr-auto">
              <h4><strong>Saturday, January 27th</strong></h4>
              <p>
                <strong>8:00-8:45am: </strong>Check-in, Breakfast, Montessori Mall<br />
                <strong>8:45-9:15am: </strong>Utah Education Fits All Scholarship Program<br />
                <strong>9:15-9:30am: </strong>Welcome<br />
                <strong>9:30-10:30am: </strong>Keynote by Dr. Ulcca Joshi Hansen: The Future of Smart<br />
                <strong>10:45am-4:15pm: </strong>Workshops, Lunch, Door Prize
                Drawing, Montessori Mall, Book Premiere: Meeting the Learning Needs of All Children<br />
                <strong>4:15-4:45pm: </strong>Closing Ceremony and Student Presentation by Elizabeth Academy<br />
              </p>
            </div>
            <div class="col-md-12 ml-auto mr-auto">
              <p><i>Bring a door prize from your school or business!</i></p>
            </div>
            <hr />
            <a
              target="_blank"
              class="btn btn-primary btn-lg text-white"
              href="downloads/UMC2024.pdf"
              ><strong>Conference Flyer</strong></a
            >
            <a
              target="_blank"
              class="btn btn-primary btn-lg text-white"
              href="https://docs.google.com/forms/d/e/1FAIpQLScx8A9kCaxNt4bElbEz3_pEQhIJWaHNsRM-ItqwAsMIIU8l5g/viewform?usp=sf_link
"
              ><strong>Submit Elders Nomination for UMC</strong></a
            >
            <a
              target="_blank"
              class="btn btn-primary btn-lg text-white"
              href="/umc-info
"
              ><strong>Presenter Bios</strong></a
            >
          </div>

          <div class="col-md-4 ml-auto mr-auto">
            <h3 class="title">Registration Information</h3>
            <p><b>Early Bird Registration:</b> $175/person until Dec. 15th</p>
            <p><b>Late Registration:</b> $190/person after Dec. 15th<br /></p>
            <p><b>Final Registration Deadline January 15th</b></p>
            <p>
              <b
                ><a
                  href="https://www.marriott.com/event-reservations/reservation-link.mi?id=1676482537396&key=GRP&app=resvlink"
                  target="_blank"
                  >Conference goers can get a special rate of $132 a
night at the Salt Lake City Marriott University Park.
To reserve at that rate follow the link below.</a
                ></b
              >
            </p>
            <a
              target="_blank"
              class="btn btn-primary btn-lg text-white"
              href="https://form.jotform.com/231655888132160"
              ><strong>Register Here!</strong></a
            >
            <p>
              Our volunteers are working to bring you a professional conference
              at the lowest possible cost. Therefore we cannot offer refunds.
            </p>
            <p>Thanks to the following schools for sponsoring the costs to bring Dr. Hansen to Utah:
Blooming Minds Montessori School, Dancing Moose Montessori School, Elizabeth Academy,
Montessori Community School, Our Journey Montessori, Soaring Wings International Montessori School.</p>

          </div>
          <div class="col-md-12 ml-auto mr-auto">
            <hr />
            <p>
              This event is open to UMC members only. Visit
              <a
                href="https://utahmontessori.org/become-a-member"
                target="_blank"
                >Become a Member</a
              >. Contact leah@soaringwings.org about advertising as a vendor
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Button, InfoSection, FormGroupInput } from "@/components";

export default {
  name: "umc-conference",
  bodyClass: "umc-conference",
  components: {
    InfoSection,
    [Button.name]: Button,
    [FormGroupInput.name]: FormGroupInput,
  },

  data() {
    return {};
  },
  metaInfo: {
    title: "Utah Montessori - UMC Conference",
    meta: [{ name: "description", content: "Sign up for the UMC Conference." }],
  },
};
</script>
<style></style>
