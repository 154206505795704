<template>
  <div class="wrapper blog-post">
    <div class="page-header page-header-small">
      <parallax
        class="page-header-image"
        style="background-image: url('img/home/utah.jpg')"
      ></parallax>
      <div class="content-center">
        <div class="row">
          <div class="col-md-8 ml-auto mr-auto text-center">
            <h2 class="title">Resources</h2>
            <h4>Items you might like.</h4>
          </div>
        </div>
      </div>
    </div>
    <div class="section">
      <div class="container">
        <div class="row">
          <div class="col-md-10 ml-auto mr-auto">
            <h3 class="title">Websites</h3>
            <h4>
              <a href="https://amshq.org/" target="_blank"
                >American Montessori Society</a
              >
            </h4>
            <h4>
              <a href="http://montessori.org/" target="_blank"
                >International Montessori Council</a
              >
            </h4>
          </div>
        </div>
        <div class="row">
          <div class="col-md-10 ml-auto mr-auto">
            <h3 class="title">Videos</h3>
            <p>
              A one minute public service spot to promote Montessori in general.
            </p>
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/eFfUSkyCOcA"
              frameborder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
            <br /><br />
            <p>
              A one minute public service spot to promote Montessori in general.
            </p>
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/q7a3Br6kPbU"
              frameborder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
            <br /><br />
            <p>Trevor Eissler "Montessori Madness!" - 321 FastDraw</p>
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/GcgN0lEh5IA"
              frameborder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
            <br /><br />
            <p>Dr. Steven Hughes</p>
            <iframe
              src="https://player.vimeo.com/video/50385456?badge=0"
              width="560"
              height="315"
              frameborder="0"
              allowfullscreen="allowfullscreen"
            ></iframe>
            <br /><br />
            <p>Why Montessori?</p>
            <video width="560" height="315" controls>
              <source src="video/Why-Montessori.mp4" type="video/mp4" />
              <source src="movie.ogg" type="video/ogg" />
              Your browser does not support the video tag.
            </video>
          </div>
        </div>
        <div class="row">
          <div class="col-md-10 ml-auto mr-auto">
            <h3 class="title">2018 Workshop References</h3>
            <h4>
              <a
                href="https://docs.google.com/presentation/d/10fnys3EbakRjapyr_M1gmsrHGrC4IM5Tjz0oZGvzAJw/edit"
                target="_blank"
                >Slide Presentation on Improvisation, created by David Armour</a
              >
            </h4>
            <h4>
              <a
                href="https://docs.google.com/presentation/d/11DUcvPkwode6riftg0GeZ-O-mxFgEx4eWlRmHOIgmng/edit#slide=id.g4329888e86_0_29"
                target="_blank"
                >The Montessori Box, slide presentation created by Aimee
                DiBrienza</a
              >
            </h4>
          </div>
        </div>
        <div class="row">
          <div class="col-md-10 ml-auto mr-auto">
            <h3 class="title">From Alisa Petersen</h3>
            <h4>
              <a
                href="https://docs.google.com/document/d/1Ul4umFOwl2oNkttW1NnT9N7PhsgkFLTvhf-5J6OlMuE/edit"
                target="_blank"
                >TAB Structures</a
              >
            </h4>
            <h4>
              <a
                href="https://docs.google.com/document/d/12L3YoWqzeFDfD7oV4xmUe-mgK2bdH6g0rIEDdnEUh7U/edit"
                target="_blank"
                >Teaching for Artistic Behavior</a
              >
            </h4>
            <h4>
              <a
                href="https://docs.google.com/document/d/1aHh_mV2WAewyebItoIqky6SWIoiJX8omCYjHbYtq_tU/edit"
                target="_blank"
                >TAB Project Prompts</a
              >
            </h4>
            <h4>
              <a
                href="https://docs.google.com/document/d/1NDlIpzf7yeXSNdojjggv9NlDmy7M0YfsOJTuX61bVBM/edit"
                target="_blank"
                >Insect Art</a
              >
            </h4>
          </div>
        </div>
        <div class="row">
          <div class="col-md-10 ml-auto mr-auto">
            <h3 class="title">2020 Workshop References</h3>
            <h4>
              <a href="downloads/TheMontessoriClassroom.pdf" target="_blank"
                >Slide Presentation on The Montessori Classroom as Culturally
                Responsive Learning Communities, created by Dr. Vanessa
                Rigaud</a
              >
            </h4>
            <h4>
              <a href="downloads/Keynote2020.pdf" target="_blank"
                >“Combing Our Strengths” keynote by Dr. Vanessa Rigaud</a
              >
            </h4>
            <h4>
              <a
                href="https://us02web.zoom.us/rec/share/G8-jKR92-V-zbrq0c1w1G_4cKcLp0RkRgGKJGugnhp1TEnl7zoncfhNZ2ffPX41H.849PUIgWbYfVzJAS "
                target="_blank"
                >“Combining Our Strengths” workshop for Infant and Toddler Level
                by Leah Linebarger, Carmen Olivera and Nathalia Van</a
              >
            </h4>
            <h4>
              “Combining Our Strengths” workshop for Lower Elementary by Kim
              Norman
            </h4>
            <h5>
              <a href="https://www.wevideo.com/view/1858403628" target="_blank"
                >Link 1</a
              >
            </h5>
            <h5>
              <a
                href="https://drive.google.com/file/d/1AGR_FKOLY5vOQk__ND7jS8MdI8uoVzwi/view?usp=sharing"
                target="_blank"
                >Link 2</a
              >
            </h5>
          </div>
        </div>
        <div class="row">
          <div class="col-md-10 ml-auto mr-auto">
            <h3 class="title">2021 UMC Fall Workshop</h3>
            <h4>
              <a href="downloads/AFrameworkforPeace.pdf" target="_blank"
                >Framework for Articulating your Very own Peace Curriculum -
                Margaret Mc Donald</a
              >
            </h4>
          </div>
        </div>
        <div class="row">
          <div class="col-md-10 ml-auto mr-auto">
            <h3 class="title">2022 UMC Conference</h3>
            <h4>
              <a href="downloads/Film.pdf" target="_blank"
                >Filmmaking in our Inclusive Classroom</a
              ><br /><br />
              <a
                href="https://docs.google.com/document/u/0/d/13tTFgWGK8FWeC-g24huGXkVQZpkVWucpFy2YmtURkF8/mobilebasic"
                target="_blank"
                >Montessori Outdoor Garden Education - Christina Economy </a
              ><br /><br />
              <a
                href="downloads/UMCIntergenerationalProject.pdf"
                target="_blank"
                >Utah Montessori Intergenerational Project - Nancy Lindeman </a
              ><br /><br />
              <a href="downloads/DevelopingDifferent.pdf" target="_blank"
                >Developing Different Kinds of Minds, 2nd Edition - Dr. Temple
                Grandin</a
              >
            </h4>
            <p><img src="downloads/SurveyCertificate.png" /></p>
          </div>
        </div>

        <div class="row">
          <div class="col-md-10 ml-auto mr-auto">
            <h3 class="title">2023 UMC Conference</h3>
            <h4>
              <a
                href="downloads/Active Listening PP - Revised-4.pdf"
                target="_blank"
                >Active Listening</a
              ><br /><br />
              <a href="downloads/Listening.pdf" target="_blank">Listening </a
              ><br /><br />
              <a href="downloads/Practice.pdf" target="_blank">Practice </a
              ><br /><br />
              <a href="downloads/RAP.pdf" target="_blank">RAP</a>
            </h4>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  Card,
  Button,
  Comment,
  Badge,
  InfoSection,
  Checkbox,
} from "@/components";

export default {
  name: "resources",
  bodyClass: "resources",
  components: {
    Card,
    InfoSection,
    Badge,
    Comment,
    [Button.name]: Button,
    [Checkbox.name]: Checkbox,
  },
  metaInfo: {
    title: "Utah Montessori - Resources",
    meta: [
      {
        name: "description",
        content: "Websites, videos, and conference resources you might like.",
      },
    ],
  },
};
</script>
<style></style>
