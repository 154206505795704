<template>
  <div class="wrapper fall-workshop">
    <div class="page-header page-header-small">
      <parallax
        class="page-header-image"
        style="background-color: #2c2a26"
      ></parallax>
      <div class="content-center">
        <div class="row">
          <div class="col-md-8 ml-auto mr-auto text-center">
            <h2 class="title">UMC Fall Workshop</h2>
            <h1>Let There Be Peace</h1>
            <h4>Saturday, October 2nd, 2021<br />A Virtual Workshop</h4>
          </div>
        </div>
      </div>
    </div>
    <div class="section">
      <div class="container">
        <div class="row">
          <div class="col-md-12 ml-auto mr-auto">
            <h3 class="title">
              Agenda & Session Information
              <small>(click boxes/arrows below to expand)</small>
            </h3>
            <collapse>
              <collapse-item
                title="9:00 - 9:30AM: Creating A Culture of Peace"
                name="2"
              >
                <div class="row">
                  <div class="col-md-4 ml-auto mr-auto">
                    <img
                      src="img/elders/nancy-full.jpeg"
                      class="img img-raised"
                    />
                  </div>
                  <div class="col-md-8 ml-auto mr-auto">
                    <p></p>
                    <h5><strong>Nancy Lindeman, MS</strong></h5>
                    <p>
                      Nancy Lindeman has been a special educator since 1978. She
                      recently retired as the program director from the
                      Institute for Montessori Innovation at Westminster
                      College. She is EC, El I-II certified and holds a
                      Montessori Administrators certificate. Nancy has founded
                      and directed a Utah public Montessori charter school and
                      two private Montessori schools. She currently serves on
                      the AMS board of directors.
                    </p>
                  </div>
                </div>
                <br />
                <div class="row">
                  <div class="col-md-4 ml-auto mr-auto">
                    <img src="img/elders/duna.jpeg" class="img img-raised" />
                  </div>
                  <div class="col-md-8 ml-auto mr-auto">
                    <p></p>
                    <h5><strong>Duna Strachan, MEd</strong></h5>
                    <p>
                      Duna Strachan was working on a master’s in Environmental
                      Zoology when a professor said, “The only way to make a
                      difference in the future of our planet is through
                      education.” Very soon thereafter her 2 ½-year-old
                      daughter, Lina, led her into their first Montessori
                      experience. And the rest is history. Duna is the Founder
                      and Executive Director of Soaring Wings International
                      Montessori School in Park City, the Founder of the Utah
                      Montessori Council and a founder for the UMC Council of
                      Elders. She holds an MEd in Montessori from St Catherine
                      University. She has worked as teacher educator and
                      consultant for Montessori organizations in the US, Canada
                      and China. Education for peace is her favorite subject.
                    </p>
                  </div>
                </div>
              </collapse-item>
              <collapse-item title="9:30 - 11:30AM: Keynote" name="10">
                <div class="row">
                  <div class="col-md-4 ml-auto mr-auto">
                    <img src="img/elders/lois.png" class="img img-raised" />
                  </div>
                  <div class="col-md-8 ml-auto mr-auto">
                    <h5><strong>Lois Bobo, PhD </strong></h5>
                    <p>
                      Lois Bobo has a Bachelor's degree in early childhood
                      education with an emphasis in Montessori education, a
                      Master's degree in educational leadership, and a PhD in
                      instructional psychology and technology. She began her
                      Montessori journey 38 years ago receiving credentials for
                      teaching 3-6, 6-9 and 9-12 year olds. She was the first
                      AMS trained elementary teacher in the state of Utah and
                      has been fully immersed in the Utah Montessori Community
                      ever since. In addition to being a classroom teacher, she
                      has been a teacher educator since 1996 teaching in both
                      Montessori teacher education programs and traditional
                      university teacher licensure programs. She is committed to
                      Montessori's vision of Peace Education and works
                      diligently to promote school environments that are
                      equitable, diverse, and inclusive. She is the executive
                      director of Walden School, a Montessori PreK-12 charter
                      school in Provo, Utah. Her current passion is children's
                      literature.
                    </p>
                  </div>
                </div>
                <br />
                <div class="row">
                  <div class="col-md-4 ml-auto mr-auto">
                    <img src="img/elders/brandi.JPG" class="img img-raised" />
                  </div>
                  <div class="col-md-8 ml-auto mr-auto">
                    <p></p>
                    <h5><strong>Brandi Allen, MEd </strong></h5>
                    <p>
                      Since 1989, Brandi Allen has taught children and adults in
                      many settings including skiing, yoga and teacher training.
                      Being a teacher in diverse conditions has taught Brandi
                      that there is a connection of humanity in all of us.
                      Brandi discovered Montessori education in 1990 and fell in
                      love with the philosophy, which she feels is a form of
                      education that focuses on the intrinsic desire that
                      children have to learn and where the prepared environment
                      sets the foundation for learning. Brandi has been involved
                      with education since 1989. Brandi has a B.S. in Elementary
                      Education from the University of Utah and a Master in
                      Education from Westminster College. She has her AMS
                      Montessori Elementary I-II Certification. Brandi and her
                      husband Earl, have three children - Nate, Emilia, and
                      Oliver with whom they love to enjoy the outdoors.
                    </p>
                  </div>
                </div>
              </collapse-item>
              <collapse-item title="12:30-2:30PM: Infant & Toddler" name="4">
                <div class="row">
                  <div class="col-md-12 ml-auto mr-auto">
                    <h5>
                      <strong>Peace Education for the Youngest Ones </strong>
                    </h5>
                    <p>
                      Peace Education for the youngest ones can seem like a
                      daunting, abstract task, but fear not, this workshop will
                      shed some light on the topic and provide you with ideas,
                      tips, and tricks. We’ll learn what Peace Education is,
                      I’ll share with you some insights from my classroom and
                      what has worked for me in my practice, and we’ll
                      brainstorm together to find some practical applications
                      for YOUR classroom. Let’s grow together.
                    </p>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-4 ml-auto mr-auto">
                    <img
                      src="img/mssara.jpg"
                      class="img img-raised"
                      width="300px"
                      height="399px"
                    />
                  </div>
                  <div class="col-md-8 ml-auto mr-auto">
                    <h5><strong>Sara Flores Miller</strong></h5>
                    <p>
                      My name is Sara. I am passionate about Peace Education and
                      believe it is vital for the future success of our
                      communities. I believe my responsibility as a guide is
                      great and therefore I am constantly looking for ways to
                      improve myself and do better for my class. I enjoy
                      fostering independence through the practical life
                      activities, the beauty of the sensorial area, and
                      encouraging creativity in my students through the
                      materials in the classroom.
                    </p>

                    <p>
                      I am an accredited Infant and Toddler teacher through the
                      American Montessori Society. I obtained my Montessori
                      certification at the Montessori Institute of Innovation at
                      Westminster College. Currently, I am a Toddler teacher at
                      Dancing Moose.
                    </p>
                  </div>
                </div>
              </collapse-item>
              <collapse-item title="12:30-2:30PM: Early Childhood" name="5">
                <div class="col-md-12 ml-auto mr-auto">
                  <div class="row">
                    <h5>
                      <strong>Description of Presentation</strong>
                    </h5>
                    <p>
                      Learn and discuss 9 practical ways to increase the peace
                      in your early childhood classroom. Look at how your
                      definition of peace guides or doesn't guide your practice
                      in your classroom. We will discuss small changes that can
                      make big impact in an early childhood classroom.
                    </p>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-4 ml-auto mr-auto">
                    <img src="img/msjen.jpg" class="img img-raised" />
                  </div>
                  <div class="col-md-8 ml-auto mr-auto">
                    <h5><strong>Jen Hall</strong></h5>
                    <p>
                      Jen Hall is an Early Childhood teacher at Maria Montessori
                      Academy. She has taught early childhood for the past 20
                      plus years. Jen received her first early childhood
                      certification with Lois Bobo in 1999. She earned a second
                      early childhood certification at Westminster in 2012. Jen
                      has taught in both private and public early childhood
                      classrooms. She has her BA in elementary education from
                      Western Governors University. Jen enjoys the new
                      challenges that each year brings. She has been blessed to
                      work with many amazing people who influenced and taught
                      her throughout her teaching career. Jen and her husband
                      have 3 amazing boys. She enjoys spending time with family
                      and being outdoors. Jen looks forward to continuing her
                      lifelong journey as a Montessori educator.
                    </p>
                  </div>
                </div>
              </collapse-item>
              <collapse-item
                title="12:30-2:30PM: Lower & Upper Elementary"
                name="6"
              >
                <div class="row">
                  <div class="col-md-12 ml-auto mr-auto">
                    <h5><strong>Description of Presentation</strong></h5>
                    <p>
                      Create your school's very own Peace Curriculum. Using a
                      framework of five areas of awareness you and your school's
                      staff can articulate an effective and dynamic peace
                      curriculum. The framework provides continuity for the
                      students as they move through each program at your school
                      and helps staff prepare and maintain a school environment
                      that nurtures a culture of cooperation and compassion.
                    </p>
                    <ol>
                      <li>Self Awareness</li>
                      <li>Community Awareness</li>
                      <li>Communication Awareness</li>
                      <li>Cultural Awareness</li>
                      <li>Environmental Awareness</li>
                    </ol>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-2 ml-auto mr-auto">
                    <img src="img/margaret.jpeg" class="img img-raised" />
                  </div>
                  <div class="col-md-10 ml-auto mr-auto">
                    <h5><strong>Margaret McDonald</strong></h5>
                    <p>
                      Margaret Mc Donald has been involved in Montessori
                      education for over 30 years as a teacher, program head,
                      Director of Curriculum and Director of Operations. In
                      August of 2020, she stepped in as co-director of
                      Montessori Community School of Salt Lake City, Utah (MCS).
                      In these past two years has worked to guide the school
                      community through a pandemic, garner accreditation from
                      the International Montessori Council (IMC) and open an
                      Infant program.
                    </p>
                    <p>
                      Margaret has Montessori teaching credentials for Nursery
                      (3-6) and Primary (6-12) levels from the London Montessori
                      Center of Dublin, Ireland and an AMI School
                      Administrator's Certificate from Whole School Leadership
                      Institute. Throughout her career, she has provided teacher
                      training, supervised interns, mentored new teachers. She
                      has collaborated with colleagues to create parent
                      education events for MCS, established a middle school
                      program, and developed curriculum for upper elementary and
                      middle school. Margaret has previously presented a variety
                      of workshops and led round table discussions for UMC.
                    </p>
                  </div>
                </div>
              </collapse-item>
              <collapse-item title="12:30-2:30PM: Administrators" name="8">
                <div class="row">
                  <div class="col-md-12 ml-auto mr-auto">
                    <h5><strong>Unlocking the Peace Within Us</strong></h5>
                    <p>
                      In this workshop we will examine the why and how of
                      finding peace within ourselves. Becoming a peaceful leader
                      will help us to nurture peace within our schools. But wait
                      a minute....does this mean I have to be vulnerable? You
                      might be right. Join us for a few laughs and a deep
                      commitment to Unlocking the Peace Within Us.
                    </p>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-4 ml-auto mr-auto">
                    <img
                      src="img/elders/nancy-full.jpeg"
                      class="img img-raised"
                    />
                  </div>
                  <div class="col-md-8 ml-auto mr-auto">
                    <h5><strong>Nancy Lindeman, MS</strong></h5>
                    <p>
                      Nancy Lindeman has been a special educator since 1978. She
                      recently retired as the program director from the
                      Institute for Montessori Innovation at Westminster
                      College. She is EC, El I-II certified and holds a
                      Montessori Administrators certificate. Nancy has founded
                      and directed a Utah public Montessori charter school and
                      two private Montessori schools. She currently serves on
                      the AMS board of directors.
                    </p>
                  </div>
                </div>
              </collapse-item>
            </collapse>
            <h3 class="title">Vendors</h3>
            <div class="row">
              <div class="col-md-6">
                <a href="https://childrenshouse.co.za/us" target="_blank"
                  ><img
                    src="img/conference/childrenshouse.png"
                    class="img center"
                /></a>
              </div>
              <div class="col-md-6">
                <a href="https://www.montessorigym.com/" target="_blank"
                  ><img src="img/conference/MGLogo.png" class="img center"
                /></a>
              </div>
            </div>
            <hr />
            <div class="row">
              <div class="col-md-6">
                <a href="https://www.forsmallhands.com/" target="_blank"
                  ><img
                    src="img/conference/forsmallhands.png"
                    class="img center"
                /></a>
              </div>
              <div class="col-md-6">
                <a href="https://www.montessoriservices.com/" target="_blank"
                  ><img
                    src="img/conference/montessoriservices.jpg"
                    class="img center"
                /></a>
              </div>
            </div>
            <hr />
            <div class="row">
              <div class="col-md-6">
                <a
                  href="https://www.etsy.com/shop/JoyfulemmmaEducation"
                  target="_blank"
                  ><img src="img/conference/joyful.jpg" class="img center"
                /></a>

                <h6 class="text-center">Montessori Materials</h6>
              </div>

              <div class="col-md-6">
                <a
                  href="https://www.discoverytoys.com/pws/lesliebagley/tabs/pw-home.aspx"
                  target="_blank"
                  ><img
                    src="img/conference/discoverytoys.png"
                    class="img center"
                /></a>

                <h6 class="text-center">
                  <span style="color: rgb(31,73,125)">Leslie Bagley</span><br />
                  <span style="color: rgb(0,176,80)">Discovery Toys</span><br />
                  <span style="color: red">(801) 254-6742 work</span><br />
                  <span style="color: rgb(0,176,240)">(801) 694-1406 cell</span
                  ><br /><br />
                  <span><i>Join my FB VIP GROUP:</i></span
                  ><br />
                  <a
                    href="https://www.facebook.com/groups/DiscoverPlay/"
                    target="_blank"
                    style="color: black"
                    >www.facebook.com/groups/DiscoverPlay</a
                  ><br />
                  toyladyleslie@hotmail.com<br />
                  <a
                    href="http://www.LesliesToys.com"
                    target="_blank"
                    style="color: black"
                    >www.LesliesToys.com</a
                  >
                </h6>
              </div>
            </div>
            <hr />
            <div class="row">
              <div class="col-md-6">
                <a href="https://sprout-kids.com/" target="_blank"
                  ><img
                    src="img/conference/sprout.png"
                    class="img center"
                    style="width: 50% !important"
                /></a>
              </div>
              <div class="col-md-6">
                <a href="https://x3023.myubam.com/" target="_blank"
                  ><img src="img/conference/usborne.png" class="img center"
                /></a>

                <h6 class="text-center">
                  Book fair opportunities for your school<br />Your school earns
                  a percentage of the sales!
                </h6>
              </div>
            </div>
            <hr />
            <div class="row">
              <div class="col-md-6">
                <a
                  href="https://janebluestein.com/2019/free-books/"
                  target="_blank"
                  ><img src="img/conference/jane.png" class="img center"
                /></a>

                <h6 class="text-center">Free books, just pay shipping</h6>
              </div>
              <div class="col-md-6">
                <a
                  href="https://www.teacherspayteachers.com/Store/My-Family-Montessori"
                  target="_blank"
                  ><img
                    src="img/conference/MyFamilyMontessori.jpg"
                    class="img center"
                    style="width: 50% !important"
                /></a>

                <h6 class="text-center">Teachers Pay Teachers</h6>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  Button,
  InfoSection,
  FormGroupInput,
  Collapse,
  CollapseItem,
} from "@/components";

export default {
  name: "fall-workshop",
  bodyClass: "fall-workshop",
  components: {
    InfoSection,
    [Button.name]: Button,
    [FormGroupInput.name]: FormGroupInput,
    Collapse,
    CollapseItem,
  },

  data() {
    return {};
  },
  metaInfo: {
    title: "Utah Montessori - Fall Workshop",
    meta: [{ name: "description", content: "Fall workshop information" }],
  },
};
</script>
<style>
.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 75%;
  margin-top: 50px;
  margin-bottom: 20px;
}
</style>
