<template>
  <navbar
    position="fixed"
    :transparent="transparent"
    :color-on-scroll="colorOnScroll"
    menu-classes="ml-auto"
  >
    <template slot-scope="{ toggle, isToggled }">
      <router-link v-popover:popover1 class="navbar-brand" to="/">
        <strong>Utah Montessori Council</strong>
      </router-link>
    </template>
    <template slot="navbar-menu">
      <li class="nav-item">
        <router-link tag="a" class="nav-link" :to="{ name: 'home' }">
          <p><strong>Home</strong></p>
        </router-link>
      </li>
      <strong>
        <drop-down tag="li" title="About Us" class="nav-item">
          <li class="nav-item active">
            <a
              class="dropdown-item"
              href="downloads/CodeofEthics.pdf"
              target="_blank"
            >
              Code of Ethics
            </a>
          </li>
          <nav-link class="dropdown-item" :to="{ name: 'council-elders' }">
            Council of Elders
          </nav-link>
          <nav-link class="dropdown-item" :to="{ name: 'history' }">
            History
          </nav-link>
          <nav-link class="dropdown-item" :to="{ name: 'member-schools' }">
            Member Schools
          </nav-link>
          <nav-link class="dropdown-item" :to="{ name: 'resources' }">
            Resources
          </nav-link>
          <nav-link class="dropdown-item" :to="{ name: 'member' }">
            Become a Member
          </nav-link>
          <nav-link class="dropdown-item" :to="{ name: 'training' }">
            Teacher Training Programs
          </nav-link>
        </drop-down>
      </strong>
      <li class="nav-item">
        <router-link tag="a" class="nav-link" :to="{ name: 'blog' }">
          <p><strong>Blog</strong></p>
        </router-link>
      </li>
      <li class="nav-item">
        <router-link tag="a" class="nav-link" :to="{ name: 'certifications' }">
          <p><strong>Certifications</strong></p>
        </router-link>
      </li>
      <li class="nav-item">
        <router-link tag="a" class="nav-link" :to="{ name: 'consultation' }">
          <p><strong>Consultation</strong></p>
        </router-link>
      </li>
      <strong>
        <drop-down tag="li" title="Events" class="nav-item">
          <nav-link class="dropdown-item" :to="{ name: 'admin-retreat' }">
            Administrator Retreat
          </nav-link>
          <nav-link class="dropdown-item" :to="{ name: 'fall-workshop' }">
            Fall Workshop
          </nav-link>
          <nav-link class="dropdown-item" :to="{ name: 'umc-conference' }">
            UMC Conference
          </nav-link>
          <hr />
          <nav-link class="dropdown-item" :to="{ name: 'call-for-presenters' }">
            Call for Presenters
          </nav-link>
          <a
            class="dropdown-item"
            href="https://form.jotform.com/231305095713147"
            target="_blank"
          >
            Vendors / Exhibitor
          </a>
          <a
            class="dropdown-item"
            href="https://docs.google.com/forms/d/e/1FAIpQLSeRAbS9wUS5aTeyHpPzTY8AVKicCTs6idi1LbvU4TrfqA2KcQ/viewform?usp=sf_link"
            target="_blank"
          >
            Sponsors
          </a>
        </drop-down>
      </strong>
      <li class="nav-item">
        <router-link
          tag="a"
          class="nav-link btn btn-primary"
          :to="{ name: 'contact' }"
        >
          <p><strong>Contact Us</strong></p>
        </router-link>
      </li>
    </template>
  </navbar>
</template>

<script>
import { DropDown, NavbarToggleButton, Navbar, NavLink } from "@/components";
import { Popover } from "element-ui";
export default {
  name: "main-navbar",
  props: {
    transparent: Boolean,
    colorOnScroll: Number,
  },
  components: {
    DropDown,
    Navbar,
    NavbarToggleButton,
    NavLink,
    [Popover.name]: Popover,
  },
};
</script>

<style scoped></style>
