<template>
  <div class="wrapper blog-post">
    <div class="page-header page-header-small">
      <parallax
        class="page-header-image"
        style="background-image: url('img/blog/rich.jpg')"
      ></parallax>
      <div class="content-center">
        <div class="row">
          <div class="col-md-8 ml-auto mr-auto text-center">
            <h2 class="title">It Is What It Is?</h2>
            <h4>Duna Strachan, BS AMS MEd, Executive Director</h4>
            <h4>Soaring Wings International Montessori School, Park City, Utah US</h4>
          </div>
        </div>
      </div>
    </div>
    <div class="section">
      <div class="container">
        <div class="row">
          <div class="col-md-10 ml-auto mr-auto">
            <h3 class="title">It Is What It Is?</h3>
            <p>We recently began a review of language development within the faculty. With young children we pay much attention to speaking clearly, succinctly and positively. We avoid baby talk or long explanations. We just tell them directly what they need to know. For instance, we might say simply, "Feet stay on the floor" instead of, "I don't want to see you climbing on the furniture because I'm afraid you may hurt yourself or break something or everyone else will start climbing on things, too." Or "I am listening for your quiet voice" instead of, "If you don’t quit yelling I will…!" We just tell them directly and simply what we expect them to do. After some consistent reminders they typically have it.</p>
            <p>And while we consider what our messages are to children, we think about our own habits of speech. We like to be very mindful about what we do and do not include in the classroom. We do not allow songs in the classroom where someone gets hurt, like squishing up baby bumble bees and the alligator who snaps those monkeys right out of the tree. We incorporate books that have real photos or realistic drawings of people rather than cartoons. We consider the language of books and songs and choose what supports the curriculum in a purposeful way, rather than something that is meant to entertain. We want to support the growth of these children in every way we can, rather than entertain them for adult convenience. Rather than videos or recorded sing-a- longs, we like to use our own voices, ideas and stories, even if we are not the best singers and actors. We want the children to see that our meaning comes from our hearts.</p>
            <p>As these children’s first teachers we pay attention to using proper grammar and diction just as we pay attention to forming letters and numbers correctly. With all this thought on what we are portraying to children we begin to wonder about the things we say to each other.</p>
            <p>As the elder member of the faculty I am fascinated with how our language evolves. I loved English through high school and college. One of my high school teachers was sure we would move to a universal language eventually. In my career as a wildlife biologist I loved learning the terminology – coverts, prey base, talon grappling, evolutionary advantage. When I became a teacher, I was fascinated with breaking the language down into the smallest component parts – m+a+p = map – pronouncing each phonetic sound cleanly and precisely. And with Montessori comes our own bag of jargon – sensorial, normalization, work cycle, mastery learning and intrinsic motivation.</p>
            <p>In working with my adult students from many parts of the world I am conscious of the American jargon I grew up using. How would my Chinese students know what I mean by “hold your horses,” “ride shotgun” or “it costs a couple of bucks”? I’ve noticed in Spanish and Mandarin there are often more eloquent ways of expression that don’t translate into English. You have to know the passion of the speaker to understand them.
            </p>
            <p>So when colloquialisms are born anew, I am always fascinated and sometimes highly annoyed. It’s interesting how “like” became popular slang in the 1980’s as “Valley Girl’ speak and I still hear young people saying, “…you know, like, I mean…” instead of just saying what they need to say. A parent of one of my students was a newswoman from Washington DC and she was perturbed by how we westerners drop our “g”s so often. I have since been trying not to do that, but it’s been decades and that habit is sticking with me. Another parent was a brilliant poet and I noticed that she never ever used colloquialisms. She always thought of her own words rather than using an oft-used phrase. I’ve been working on that, too, but I often grab a convenient phrase when I need to cut to the chase and may have bitten off more than I can chew. Then there’s t-dropping - I heard someone on tv say a name that I didn’t recognize because she drops her “t”s. The story continued and I was still stuck on who she was talking about – Oh! Martin, not Mar-in.</p>
            <p>I kind of like the terms “lean in”, “dig/dive into”, “unpack” and “intentional” because they suggest that you invest more in what is about to be said. But why do people have to ask you “does that makes sense?” Wouldn’t I stop you and ask questions if it didn’t make sense? Or are they meaning to ask you if you are paying attention because you have a glazed-over look?</p>
            <p>My latest language peeve is how popular it’s become to throw in the phrase, “that being said”. I suppose this is to make you realize you have just been told something. But isn’t that the goal of speaking? To say something? Or is the purpose to give the speaker a second to figure out where they are going with this point? Our English teachers told us all not to say “uh”, “um”, or “but – umm” so maybe substituting, “That being said” makes us sound a little more erudite as we flail for a forgotten point.</p>
            <p>I am always perplexed when someone starts a sentence with “to be honest” because I immediately assume they must lie to me most of the time. I have even asked people if they usually lie and they laugh, but keep using that phrase. So, I guess they do. </p>
            <p>Modern slang just gets more and more abstract. What might have started as, “Good day, friend. How are you today?” became “What’s happening?” then “What’s up?” then “’Sup?” and now “Suh.” It took me awhile to figure out what she meant when a friend told me she had “serious FOMO.” I thought she must have been ill.</p>
            <p>Jerry Seinfeld points out that people like to use the same word twice, ostensibly to emphasize the meaning – “rules are rules”, “who’s who”, “business is business”, “a deal’s a deal”, “what’s what”, “whatever happens, happens”. Or do we use these phrases when we can’t tell you why, it just is? Do we get so busy reading texts, emails, social media, news updates and attending Zoom meetings, that when we do have a real conversation it becomes filled with phrases we have gleaned from all of this input? Maybe. As my English teacher prophesied, is our language blending into a universal language of acronyms and abbreviations? Maybe. But we educators will never give up teaching children how to tie shoes, write in cursive, tell time on an analog clock, recite poems, enunciate and type full words in texts and DMs. Because we are stubborn like that. And every one of those tasks teaches more than the apparent outcome. We will keep observing the evolution of language with amusement, annoyance and fascination. Because, BTW, it is what it is. </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Card, Button, Comment, Badge, InfoSection, Checkbox } from '@/components';

export default {
  name: 'itiswhatitis',
  bodyClass: 'blog-post',
  components: {
    Card,
    InfoSection,
    Badge,
    Comment,
    [Button.name]: Button,
    [Checkbox.name]: Checkbox
  },
  metaInfo: {
    title: 'Utah Montessori - It Is What It Is?',
    meta: [
      { name: 'description', content: 'We recently began a review of language development within the faculty. With young children we pay much attention to speaking clearly, succinctly and positively. We avoid baby talk or long explanations. We just tell them directly what they need to know.' }
    ]
  }
}
</script>
<style>
</style>
