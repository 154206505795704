<template>
  <div class="wrapper consultation">
    <div class="page-header page-header-small">
      <parallax
        class="page-header-image"
        style="background-image: url('img/home/utah.jpg')"
      ></parallax>
      <div class="content-center">
        <div class="row">
          <div class="col-md-8 ml-auto mr-auto text-center">
            <h2 class="title">Consultation</h2>
            <h4>Offered to UMC Members Only.</h4>
          </div>
        </div>
      </div>
    </div>
    <div class="section">
      <div class="container">
        <div class="row">
          <div class="col-md-10 ml-auto mr-auto">
            <h3 class="title">Consultation</h3>
            <p>Membership is open to all; you don’t need to be a Montessorian or a Montessori school. All you have to do is complete the one page application and agree to abide by the Code of Ethics and it’s free!</p>
            <p>The UMC provides on site consultations to assist in the development of more efficient, professional and marketable Montessori school environments. We are happy to visit your school in order to offer advice pertaining to environmental design, teaching techniques, scheduling and general improvements to help your school become more productive and appealing.</p>
            <p>The UMC provides on site consultations to assist in the development of more efficient, professional and marketable Montessori school environments. We are happy to visit your school in order to offer advice pertaining to environmental design, teaching techniques, scheduling and general improvements to help your school become more productive and appealing.</p>
            <p>Please review qualifications below and contact the consultant of your choice directly.</p>
          </div>
        </div>
        <div class="row mt-3 justify-content-center">
          <div class="col-md-12 col-lg-12">
            <card
              type="profile"
              plain
            >
              <img
                slot="avatar"
                class="img img-raised"
                src="img/elders/duna.jpg"
              />
              <h3 class="card-title">Duna Strachan</h3>
              <h6 class="category text-primary">BS AMS MEd</h6>
              <h6 class="">Founder/Executive Director</h6>
              <p class="card-description">Soaring Wings International Montessori School</p>
              <router-link
                tag="a"
                class="btn btn-primary"
                :to="{name: 'duna-strachan'}"
              ><strong>Learn More</strong>
              </router-link>
            </card>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Card, Button } from '@/components';

export default {
  name: 'consultation',
  bodyClass: 'consultation',
  components: {
    Card,
    [Button.name]: Button
  },
  metaInfo: {
    title: 'Utah Montessori - Consultation',
    meta: [
      { name: 'description', content: 'Need assistance in development of school environments?' }
    ]
  }
}
</script>
<style>
</style>
