<template>
  <div class="wrapper blog-post">
    <div class="page-header page-header-small">
      <parallax
        class="page-header-image"
        style="background-image: url('img/blog/rich.jpg')"
      ></parallax>
      <div class="content-center">
        <div class="row">
          <div class="col-md-8 ml-auto mr-auto text-center">
            <h2 class="title">Education For Peace</h2>
            <h4>Duna Strachan, BS AMS MEd, Executive Director</h4>
            <h4>Soaring Wings International Montessori School, Park City, Utah US</h4>
          </div>
        </div>
      </div>
    </div>
    <div class="section">
      <div class="container">
        <div class="row">
          <div class="col-md-10 ml-auto mr-auto">
            <h3 class="title">Education For Peace</h3>
            <p>In every Montessori classroom around the planet you will find globes, maps and flags of the world. And in a Montessori class we take the study of geography many steps further. There are also rattles and baskets from each culture in the infant classes, dolls and native textiles to try on in the toddler and early childhood classes, research and expeditionary experiences in the elementary, middle school and high school classes. And throughout the schools there are songs, stories, art, games, dances and foods so that the study of every continent is a sensory experience at every level, often culminating in a cultural immersion celebration. The goal is for each student not only to emerge with a well-rounded development in academics, social and emotional skills, but also to lay a foundation for a lifelong respect for and celebration of the diversity of the world.</p>
            <p>When Dr. Maria Montessori founded her first school in 1907, she quickly found that these youngest students, 3 to 6 years old and considered unteachable at the time, were perfectly capable of learning self-care and academic skills on par with older children in the traditional schools of Rome. She began a career of advocating for children through her publications, worldwide lectures and establishment of Montessori schools. The leaders of many countries flocked to see how she was achieving this miracle. How could a child of 4 years be reading, writing and caring for others? Montessori enjoyed the philosophical discussions she had with people like Erik Erikson, Seigmund Freud, President Woodrow Wilson, Thomas Edison, Helen Keller and Mahatma Ghandi. It was during her years in India and through the World Wars that she came to found the tenets of education for peace.</p>
            <p>Montessori suggested that if we can teach children to share limited resources (inspired by keeping only one of each piece of equipment on the school shelves), resolve conflicts peacefully, celebrate all cultures and respect all living and non-living things we may be able to eliminate future wars. Montessori died in 1952 but she left the plea below upon her grave:</p>
            <blockquote>I beg the dear, all-powerful children to join me in creating peace in humanity and in the world.</blockquote>
            <p>When we take the Montessori teacher training courses we study this philosophy in detail and though the subsequent transformation of the teacher we come to embody this clear goal. Montessori schools are popularly known for teaching children to read and write at a precocious age, but the goal we teachers hold dear is to unlock for each child the beauty and wonderous variety of the cosmos, and how each individual can contribute to our role therein. As absorbed as we become in our sandpaper letters and numbers, we Montessorians also become absorbed in the details of how the universe, planet and all living things are in a delicate balance. We immerse ourselves in art and artists through all time, as well as authors, composers, inventors and explorers of every part of the world. Geography studies begin with functional geography to explain the mountains, rivers and oceans, but branch out to include the endemic species and cultures of each continent. This study of the Earth begins simply in the infant classes with objects, textures, fabrics, tastes and smells that may be unfamiliar, but are safe and welcome. As the child grows, layers are added, always in joyous celebration of the diversity of our world. By the middle school years students have learned much about global issues and are ready to make intentional expeditions. They may help build a school, work a field, or give aid where needed. By high school Montessori students have not only an understanding of the world around them, but experience in how to contribute in a meaningful way.</p>
            <p>As Montessori described, education for peace begins at birth. It is in the way we hold and speak to the baby, allowing freedom of movement, anticipating needs and furnishing a rich environment to explore with every sense. Maintaining plants and animals in every class begins the lessons of respect for all living things as well as the child’s curiosity about science. We make a point of including art and music from many cultures, photographs of real families of the world, books, stories, songs and foods from each continent under study. Montessori schools welcome family participation in cultural celebrations so every student is included. From infancy through high school Montessori schools build upon experiential learning, adding additional challenge and introspection at each level.</p>
            <p>In a time when health, safety and efficiency are foremost, Montessori schools have managed to stay safe and hang onto the beautiful, rich culture of our curriculum. Yes, students are wearing masks. Yes, they are at separate work spaces. Yes, we must wash hands, sanitize and stay alert for COVID symptoms constantly. But students are also able to see, feel, hear, smell and taste each facet of our cultural curriculum. When you step into a Montessori classroom, the beauty, the peace and the joy tells you education for peace is at work here.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Card, Button, Comment, Badge, InfoSection, Checkbox } from '@/components';

export default {
  name: 'educationforpeace',
  bodyClass: 'blog-post',
  components: {
    Card,
    InfoSection,
    Badge,
    Comment,
    [Button.name]: Button,
    [Checkbox.name]: Checkbox
  },
  metaInfo: {
    title: 'Utah Montessori - Education For Peace',
    meta: [
      { name: 'description', content: 'In every Montessori classroom around the planet you will find globes, maps and flags of the world. And in a Montessori class we take the study of geography many steps further.' }
    ]
  }
}
</script>
<style>
</style>
