<template>
  <div class="wrapper blog-post">
    <div class="page-header page-header-small">
      <parallax
        class="page-header-image"
        style="background-image: url('img/home/utah.jpg')"
      ></parallax>
      <div class="content-center">
        <div class="row">
          <div class="col-md-8 ml-auto mr-auto text-center">
            <h2 class="title">UMC History</h2>
            <h4>Learn about how it started.</h4>
          </div>
        </div>
      </div>
    </div>
    <div class="section">
      <div class="container">
        <div class="row">
          <div class="col-md-10 ml-auto mr-auto">
            <h3 class="title">History</h3>
            <p>In the spring of 2003 while attending the annual NCME conference in San Diego, Duna Strachan, Director of Soaring Wings Montessori School in Park City, was struck by the fact that of the few Utahns who attended the national conference many were hard-working teachers who had largely paid their own way. In order to provide a professional, affordable, accessible conference experience for every Montessori director, teacher, assistant, staff member and parent in Utah, the founding conference of the Utah Montessori Council was held at SWMS on January 31, 2004. Heads of every major school enthusiastically stepped forward to lend their support. Founding officers were elected and the mission of establishing a supportive base and inclusive networking opportunities for all members regardless of school affiliation was laid down.</p>
            <p>In ensuing years the annual conference site has shifted from school to school and attendance has grown each year. With volunteer efforts of presenters and members and generous donations of time and funds from host schools the costs of putting on the conference have been kept to a minimum so that registration fees can be kept affordable for all.</p>
          </div>
        </div>
      </div>
    </div>
    <div class="section-blog col-md-10 ml-auto mr-auto">
      <div class="container">
        <div class="section-story-overview">
          <div class="row">
            <div class="col-md-6">
              <div
                class="image-container image-left"
                style="background-image: url('img/history/umc.jpg')"
              >
              </div>
            </div>
            <div class="col-md-5">
              <!-- First image on the right side, above the article -->
              <div
                class="image-container image-right"
                style="background-image: url('img/history/lois.jpg')"
              >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="">
      <div class="container">
        <div class="row">
          <div class="col-md-10 ml-auto mr-auto">
            <p>Member schools are eligible to receive directories, tuition surveys, consultations and notices of community events that may enhance or affect schools. Officers make a point of getting out to visit member schools, welcome new schools and encourage communication between members.</p>
            <p>In 2007 the treasury reported sufficient funds to include a paid presenter allowing the annual conference to include nationally known keynote speaker, Sonnie McFarland, in addition to the roster of local volunteer presenter talent.</p>
            <p>In 2008, after 4 years of re-electing the same officers, the UMC members voted to reorganize, eliminate the by-laws and establish officers in permanent positions until changes become necessary.</p>
            <p>In 2009, the UMC website was launched offering website links for all member schools. At the same time UMC Treasurer, Bruce King, with the help of a few other Montessorians successfully convinced the Utah State Legislature to allow the exemption of Montessori schools from state daycare laws, recognizing their self-regulating structure. In addition Bruce established a system of UMC school certifications available to member schools.</p>
            <p>In September of 2009 the UMC Fall Workshop was offered for the first time featuring Donohue Shortridge and Janet Engel. The workshop was underwritten by the UMC so that members were able to attend the full day workshop for just $25.</p>
            <p>In 2010 the founding members of the UMC Council of Elders were presented, those with over 20 years experience in Montessori education who were nominated by their schools and who accepted invitations to the Council.</p>
            <div class="row">
              <p class="col-md-5 ml-auto mr-auto">Virginia Anderson, Valley Montessori School, Logan<br>
                Lois Bobo, PhD, Western Governors University<br>
                Carrol Bybee, Discovery Academy, Elizabeth Academy<br>
                Robyn Eriwata-Buchanan, Montessori Community School, Salt Lake City <br>
                Pat Fryer, Montessori of Riverton, Riverton</p>
              <p class="col-md-5 ml-auto mr-auto">Nancy Lindeman. Maria Montessori Academy, Ogden<br>
                Bobbi Nichols, Montessori of Olympus Cove, Sandy<br>
                Duna Strachan, Soaring Wings International Montessori School, Park City <br>
                Julie Wand, Dayspring Montessori School, St. George<br>
                Valerie Whitehead, Montessori at Riverton, Riverton</p>
            </div>
            <p>More than 200 people attended the 7th Annual UMC conference in February 2010 at the Montessori Community School in Salt Lake City featuring keynote speaker Jonathan Wolff. As the annual Utah Montessori Council Conference continues to grow members overwhelmingly report a sense of rejuvenating satisfaction at the professional development opportunities offered and the warmth of being reunited with a consistent support system much like a family reunion where everyone is welcome.</p>
            <p>The Utah Montessori Council is an organization of volunteers representing schools throughout Utah and surrounding states, with the mission of providing affordable professional development experiences, support and networking opportunities for member schools regardless of affiliation.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Card, Button, Comment, Badge, InfoSection, Checkbox } from '@/components';

export default {
  name: 'blog-post',
  bodyClass: 'blog-post',
  components: {
    Card,
    InfoSection,
    Badge,
    Comment,
    [Button.name]: Button,
    [Checkbox.name]: Checkbox
  },
  metaInfo: {
    title: 'Utah Montessori - History',
    meta: [
      { name: 'description', content: 'Learn about how UMC started.' }
    ]
  }
}
</script>
<style>
</style>
