<template>
  <div class="wrapper duna-strachan">
    <div class="page-header page-header-small">
      <parallax
        class="page-header-image"
        style="background-image: url('img/home/utah.jpg')"
      ></parallax>
      <div class="content-center">
        <div class="row">
          <div class="col-md-8 ml-auto mr-auto text-center">
            <h2 class="title">Duna Strachan, BS AMS MEd</h2>
            <h4>Founder / Executive Director</h4>
            <h4 class="mt-0">Soaring Wings International Montessori School</h4>
          </div>
        </div>
      </div>
    </div>
    <div class="section">
      <div class="container">
        <div class="row">
          <div class="col-md-10 ml-auto mr-auto">
            <h3 class="title">About Duna</h3>
            <p>Duna founded Soaring Wings in 1987. She received a BS in Wildlife Biology from Arizona State University and was in the midst of a Master’s degree in Environmental Zoology when parenthood suddenly drew her attention to the world of Montessori. Duna found that her science background lent itself to the science based Montessori method and enabled her to be a “Renaissance woman”, dabbling in art, music, geography, literature and math as well as science. Duna holds Montessori credentials at the Infant/Toddler and Early Child levels. She was founding president of the Utah Montessori Council, coordinates the annual state teacher conference and hosts teacher training workshops. She lives in Park Meadows with her husband, best friend, and administrator, Bruce King. Her two Montessori children, Lina and Leith, are grown but the family still enjoys frequent boating expeditions. Duna also received her MEd from St Catherine University. <a
                href="downloads/Duna_Resume.pdf"
                target="_blank"
                class="btn btn-primary btn-round"
              ><strong>Click here to download Duna’s résumé.</strong></a> </p>
            <p>As Executive Director of Soaring Wings, Duna oversees classroom quality, curriculum integrity, faculty development and community projects aimed at continual improvement and growth of the school.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Card, Button } from '@/components';

export default {
  name: 'duna-strachan',
  bodyClass: 'duna-strachan',
  components: {
    Card,
    [Button.name]: Button
  },
  metaInfo: {
    title: 'Utah Montessori - Duna Strachan',
    meta: [
      { name: 'description', content: 'Duna founded Soaring Wings in 1987. She received a BS in Wildlife Biology from Arizona State University and was in the midst of a Master’s degree in Environmental Zoology when parenthood suddenly drew her attention to the world of Montessori.' }
    ]
  }
}
</script>
<style>
.mt-0 {
  margin-top: 0 !important;
}
</style>
