<template>
  <div class="wrapper fall-workshop">
    <div class="page-header page-header-small">
      <parallax
        class="page-header-image"
        style="background-image: url('img/workshop/future.jpg')"
      ></parallax>
      <div class="content-center">
        <div class="row">
          <div class="col-md-8 ml-auto mr-auto text-center">
            <h2 class="title">21st Annual UMC Conference</h2>
            <h4>
              <strong>
                January 26 & 27, 2024<br />The Marriott University Park<br />Salt
                Lake City</strong
              >
            </h4>
          </div>
        </div>
      </div>
    </div>
    <div class="section">
      <div class="container">
        <div class="row">
          <div class="col-md-12 ml-auto mr-auto">
            <collapse>
              <h3 class="title">
                Workshop Presenters
                <small>(click boxes/arrows below to expand)</small>
              </h3>

              <collapse-item title="WORKSHOP A: 10:45 - 11:45 AM" name="1">
                <div class="row">
                  <div class="col-md-4 ml-auto mr-auto">
                    <h4>Catherine Mathews</h4>
                    <img
                      src="img/presenters24/Catherine Mathews.jpeg"
                      height="300px"
                      width="200px"
                    />
                  </div>
                  <div class="col-md-8 ml-auto mr-auto mt-auto mb-auto">
                    <p>
                      Catherine Mathews has been working in Montessori for over
                      20 years in both the Early Childhood and Elementary
                      levels. She holds an Early Childhood certificate and is
                      completing her Leadership Certificate through CGMS.
                      Catherine is the originator of the Helpful Garden blog and
                      is a founding partner of Foresight Montessori. She has
                      served as a teacher trainer, field consultant, and mentor
                      to adults in the private, public, and homeschooling
                      sectors. Catherine really loves literacy and overcoming
                      obstacles to reading success through innovative means all
                      while maintaining high fidelity to the principles of
                      Montessori.
                    </p>
                  </div>
                </div>
                <hr />
                <div class="row">
                  <div class="col-md-4 ml-auto mr-auto">
                    <h4>Jessica Rawlings</h4>
                    <img
                      src="img/presenters24/Jessicarawlingsphoto2023 - Jessica Rawlings.jpg"
                      height="300px"
                      width="200px"
                    />
                  </div>
                  <div class="col-md-8 ml-auto mr-auto mt-auto mb-auto">
                    <p>
                      Jessica has a MEd from Westminster College and a
                      Montessori 3-6 credential. She was a lead guide for many
                      years, has over 5 years in school leadership, and enjoys
                      work with adult education.
                    </p>
                    <p>
                      Create a Montessori classroom where mistakes are
                      opportunities to learn, every community member is treated
                      with respect, and clear expectations support grace and
                      courtesy.
                    </p>
                  </div>
                </div>
                <hr />
                <div class="row">
                  <div class="col-md-4 ml-auto mr-auto">
                    <h4>Annie Hays</h4>
                    <img
                      src="img/presenters24/Annie Hays.jpeg"
                      height="300px"
                      width="200px"
                    />
                  </div>
                  <div class="col-md-8 ml-auto mr-auto mt-auto mb-auto">
                    <p>
                      My name is Annie Hays, and I have been working with young
                      children for 8 years. I hold a Bachelor’s Degree in Human
                      Development, and my Early Childhood Montessori teaching
                      credential from CGMS. Last year was my first as a teacher
                      mentor, where I started presenting workshops and trainings
                      to support adult learning in Montessori. Let’s set your
                      classroom up for success during the trickiest times of of
                      your school day. This workshop will cover fun and
                      respectful ways to approach classroom management, while
                      fostering independence and peer support to create a
                      classroom community, all throughout the day.
                    </p>
                  </div>
                </div>
                <hr />
                <div class="row">
                  <div class="col-md-4 ml-auto mr-auto">
                    <h4>Andrew Kutt</h4>
                    <img
                      src="img/presenters24/Andrew Kutt.png"
                      height="300px"
                      width="200px"
                    />
                  </div>
                  <div class="col-md-8 ml-auto mr-auto mt-auto mb-auto">
                    <p>
                      Andrew Kutt is Founder & Chairman of the Board at
                      Oneness-Family Montessori School in Chevy Chase MD and the
                      Secretary of the International Montessori Council. For
                      over 35 years Andrew has contributed to the Montessori
                      community as a teacher, administrator, teacher trainer,
                      leadership consultant, speaker and performer. Andrew is an
                      accomplished poet and songwriter, with 6 albums and over
                      100 songs to his credit. His latest volume of poetry is
                      entitled Flamed Epiphanies, and his new album Shine On
                      will be released November 1. To learn more visit
                      www.andrewkuttmusic.com and www.onenessfamily.org.
                    </p>
                    <p>
                      In this workshop Andrew Kutt will share 7 lessons he
                      learned as a youth working at his brother’s country store
                      Joe’s Market. These lessons are applicable to everyone
                      trying to build a vibrant Montessori community, whether
                      you are a teacher, administrator, or board member.
                      Attendees will create their own mini action plan to take
                      back to their classrooms and schools. Of course, Andrew
                      will be also sharing some music and stories.
                    </p>
                  </div>
                </div>
                <hr />
                <div class="row">
                  <div class="col-md-4 ml-auto mr-auto">
                    <h4>Leith Strachan</h4>
                    <img
                      src="img/presenters24/Leith Strachan.jpg"
                      height="300px"
                      width="200px"
                    />
                  </div>
                  <div class="col-md-8 ml-auto mr-auto mt-auto mb-auto">
                    <p>
                      Former Montessori student and U.S. Army Veteran is now the
                      Administrator at Soaring Wings International Montessori
                      School. Holds a BS in Unmanned Systems from Embry-Riddle
                      Aeronautical University and is completing the Montessori
                      Leadership Credential at CGMS. How to incorporate The
                      World Peace Game in your Elementary, Middle School, and
                      High School classrooms.
                    </p>
                  </div>
                </div>
                <hr />
                <div class="row">
                  <div class="col-md-4 ml-auto mr-auto">
                    <h4>Julie Wand</h4>
                    <img
                      src="img/presenters24/Julie Wand.png"
                      height="300px"
                      width="200px"
                    />
                  </div>
                  <div class="col-md-8 ml-auto mr-auto mt-auto mb-auto">
                    <p>
                      I currently serve as advisor and Instructor, for Rings of
                      Knowledge Montessori Teacher Education Program. I hold a
                      M.Ed. and Montessori certificates for children 3-12. I
                      have 36 years of experience as a Montessori educator. My
                      expertise includes, curriculum and instruction, design,
                      and evaluation, of early childhood and K-8 literacy.
                    </p>
                    <p>
                      Beyond Decoding will help participants learn how to bring
                      Reading as a Tool (The Science of Reading) into the
                      classroom everyday. It will help teachers generate and
                      find text that engages and excites children to be inspired
                      to learn the skills required to become life long readers.
                    </p>
                  </div>
                </div>
                <hr />
                <div class="row">
                  <div class="col-md-4 ml-auto mr-auto">
                    <h4>Shauna Garner</h4>
                    <img
                      src="img/presenters24/Shauna Garner.jpg"
                      height="300px"
                      width="200px"
                    />
                  </div>
                  <div class="col-md-8 ml-auto mr-auto mt-auto mb-auto">
                    <p>
                      Shauna has 20+ years of Montessori teaching in both public
                      and private schools. Shauna has offered sewing, weaving,
                      knitting, as well as other work in the classroom.
                    </p>
                    <p>
                      A hands-on presentation with fiber and how fiber can be
                      present in all parts of a Montessori classroom.
                      Participants will see various ways to present and make
                      fibers in their own classroom.
                    </p>
                  </div>
                </div>
                <hr />
                <div class="row">
                  <div class="col-md-4 ml-auto mr-auto">
                    <h4>Stephanie Van Fleet</h4>
                    <img
                      src="img/presenters24/Stephanie Van Fleet.jpg"
                      height="300px"
                      width="200px"
                    />
                  </div>
                  <div class="col-md-8 ml-auto mr-auto mt-auto mb-auto">
                    <p>
                      Stephanie Van Fleet, MAed, is an AMS Elementary I & II
                      credentialed Montessorian who is currently a Montessori
                      doctoral student at the University of Wisconsin River
                      Falls, where she also serves as a graduate assistant and
                      adjunct instructor.
                    </p>
                    <p>
                      Montessori education in the United States continues its
                      heartfelt efforts to serve as many children as possible.
                      However, there are conflicting viewpoints on what Dr.
                      Montessori would say about today's issues that divide
                      classrooms, schools, and organizations. These issues range
                      from the manufacturer of classroom materials to whether or
                      not to recite the Pledge of Allegiance with students. I
                      intend to advocate for recognizing the cardinal virtues
                      embedded in the Montessori method and their aim to educate
                      with an ethical and virtuous mindset in order to establish
                      a common ground.
                    </p>
                  </div>
                </div>
                <hr />
                <div class="row">
                  <div class="col-md-4 ml-auto mr-auto">
                    <h4>Ulcca Joshi Hansen</h4>
                    <img
                      src="img/presenters24/Ulcca Hansen.jpg"
                      height="300px"
                      width="200px"
                    />
                  </div>
                  <div class="col-md-8 ml-auto mr-auto mt-auto mb-auto">
                    <p>
                      Ulcca Joshi Hansen is a mother, educator, researcher and
                      advocate working to change the way we think about and do
                      education. She is also author of the award-winning book
                      "The Future of Smart." She believes each young person
                      deserves the chance to discover their unique potential,
                      and to explore what that means for how they contribute to
                      the world. Making this happen is the future of smart. It
                      requires us to expand access to relationship-based,
                      relevant and real-world learning experiences for young
                      people and adults throughout their careers.
                    </p>
                    <p>
                      An internationally-recognized expert on educational
                      transformation at the level of instruction, assessment,
                      organizational design and policy systems, Ulcca is a
                      two-time TED speaker and has been recognized
                      internationally and in the Montessori for her leadership
                      in educational systems change.
                    </p>
                    <p>
                      This workshop will be a follow up from the keynote and
                      will be designed accordingly based on conversations with
                      conveners.
                    </p>
                  </div>
                </div>
              </collapse-item>
              <collapse-item title="WORKSHOP B: 1:45 - 2:45 PM" name="2">
                <div class="row">
                  <div class="col-md-4 ml-auto mr-auto">
                    <h4>Mercedes Castle</h4>
                    <img
                      src="img/presenters24/MercedesCastle.jpeg"
                      height="300px"
                      width="200px"
                    />
                  </div>
                  <div class="col-md-8 ml-auto mr-auto mt-auto mb-auto">
                    <p>
                      Mercedes Paine Castle, M.Ed. (Educational Leadership) is
                      an Assistant to Infancy (AMS), Montessori advocate, wife,
                      mother, and agent of change. She has been guiding toddlers
                      towards independence since 2003. Mercedes is a head of
                      school and teacher educator. Like Dr. Maria Montessori,
                      Mercedes believes that “The task of educators is immense
                      because human progress and world peace are in their hands”
                      (Montanaro, 1991, p. 165).
                    </p>
                    <p>
                      Let's look at our program goals and values - how do they
                      intersect for our tiniest learners? Where do we find the
                      evidence in our day to day practice that we are living our
                      values, and what does building peace look like if we focus
                      on one of the most important caregiving rituals -
                      mealtimes.
                    </p>
                  </div>
                </div>
                <hr />
                <div class="row">
                  <div class="col-md-4 ml-auto mr-auto">
                    <h4>Nathalia Van</h4>
                    <img
                      src="img/presenters24/Nathy Van.jpeg"
                      height="300px"
                      width="200px"
                    />
                  </div>
                  <div class="col-md-8 ml-auto mr-auto mt-auto mb-auto">
                    <p>
                      I hold my AMS credential in Infant/Toddler since 2019 I
                      have been a Lead Toddler Teacher for the past 6 years.
                      Workshop en español. Donde se hablara sobre la filosofía
                      Montessori
                    </p>
                  </div>
                </div>
                <hr />
                <div class="row">
                  <div class="col-md-4 ml-auto mr-auto">
                    <h4>Nancy Lindeman</h4>
                    <img
                      src="img/presenters24/Nancy Lindeman.JPG"
                      height="300px"
                      width="200px"
                    />
                  </div>
                  <div class="col-md-8 ml-auto mr-auto mt-auto mb-auto">
                    <p>
                      Nancy Lindeman has been a special educator since 1978. She
                      retired as an AMS TEP program director. Nancy holds a MA
                      in educational leadership and Montessori EC, EI-II, and
                      Administrators certificates and she is in the UWRF
                      Montessori studies EdD program.
                    </p>
                    <p>
                      Does inequity in our schools cause or worsen student
                      trauma? In this workshop we will dig into the meaning of
                      educational equity and how it connects to trauma-informed
                      practice.
                    </p>
                  </div>
                </div>
                <hr />
                <div class="row">
                  <div class="col-md-4 ml-auto mr-auto">
                    <h4>Kim Norman</h4>
                    <img
                      src="img/presenters24/Kim Norman.jpg"
                      height="300px"
                      width="200px"
                    />
                  </div>
                  <div class="col-md-8 ml-auto mr-auto mt-auto mb-auto">
                    <p>
                      Kim Norman is a mom and holds Montessori credentials at
                      the Early Childhood and Lower Elementary levels. She has
                      been teaching in Montessori settings for 17 years and
                      teaching in other settings for over 20 years. She is
                      currently teaching at The Elizabeth Academy Upper Campus
                      in the STEM department. She is a field consultant for The
                      Center for Guided Montessori Studies and enjoys mentoring
                      new Montessorians. She is passionate about trees, plants,
                      and her students.
                    </p>
                    <p>
                      As we inspire students to find their "Cosmic Task,"
                      Montessori's educational cornerstones of Cosmic and Peace
                      Education can guide us and our students to heal the Earth
                      and make peace with our planet.
                    </p>
                  </div>
                </div>
                <hr />
                <div class="row">
                  <div class="col-md-4 ml-auto mr-auto">
                    <h4>Catherine Mathews</h4>
                    <img
                      src="img/presenters24/Catherine Mathews.jpeg"
                      height="300px"
                      width="200px"
                    />
                  </div>
                  <div class="col-md-8 ml-auto mr-auto mt-auto mb-auto">
                    <p>
                      Catherine Mathews has been working in Montessori for over
                      20 years in both the Early Childhood and Elementary
                      levels. She holds an Early Childhood certificate and is
                      completing her Leadership Certificate through CGMS.
                      Catherine is the originator of the Helpful Garden blog and
                      is a founding partner of Foresight Montessori. She has
                      served as a teacher trainer, field consultant, and mentor
                      to adults in the private, public, and homeschooling
                      sectors.
                    </p>
                    <p>
                      Catherine really loves literacy and overcoming obstacles
                      to reading success through innovative means all while
                      maintaining high fidelity to the principles of Montessori.
                    </p>
                  </div>
                </div>
                <hr />
                <div class="row">
                  <div class="col-md-4 ml-auto mr-auto">
                    <h4>Julie Wand</h4>
                    <img
                      src="img/presenters24/Julie Wand.png"
                      height="300px"
                      width="200px"
                    />
                  </div>
                  <div class="col-md-8 ml-auto mr-auto mt-auto mb-auto">
                    <p>
                      I currently serve as advisor and Instructor, for Rings of
                      Knowledge Montessori Teacher Education Program. I hold a
                      M.Ed. and Montessori certificates for children 3-12. I
                      have 36 years of experience as a Montessori educator. My
                      expertise includes, curriculum and instruction, design,
                      and evaluation, of early childhood and K-8 literacy.
                    </p>
                    <p>
                      Beyond Decoding will help participants learn how to bring
                      Reading as a Tool (The Science of Reading) into the
                      classroom everyday. It will help teachers generate and
                      find text that engages and excites children to be inspired
                      to learn the skills required to become life long readers.
                    </p>
                  </div>
                </div>
                <hr />
                <div class="row">
                  <div class="col-md-4 ml-auto mr-auto">
                    <h4>Shauna Garner</h4>
                    <img
                      src="img/presenters24/Shauna Garner.jpg"
                      height="300px"
                      width="200px"
                    />
                  </div>
                  <div class="col-md-8 ml-auto mr-auto mt-auto mb-auto">
                    <p>
                      Shauna has 20+ years of Montessori teaching in both public
                      and private schools. Shauna has offered sewing, weaving,
                      knitting, as well as other work in the classroom.
                    </p>
                    <p>
                      A hands-on presentation with fiber and how fiber can be
                      present in all parts of a Montessori classroom.
                      Participants will see various ways to present and make
                      fibers in their own classroom.
                    </p>
                  </div>
                </div>
                <hr />
                <div class="row">
                  <div class="col-md-4 ml-auto mr-auto">
                    <h4>Nicoletta Householder</h4>
                    <img
                      src="img/presenters24/Nicoletta Householder.jpeg"
                      height="300px"
                      width="200px"
                    />
                  </div>
                  <div class="col-md-8 ml-auto mr-auto mt-auto mb-auto">
                    <p>
                      Nicoletta Householder- I have been in Montessori since
                      2005. I am lower and upper elementary certified and have
                      received my masters in Montessori from St. Catherine's
                      University. I am currently the Assistant Director at Maria
                      Montessori Academy and prior was the Instructional Coach
                      and an Upper Elementary teacher.
                    </p>
                    <p>
                      Teaching, learning and following the "whole child" in the
                      upper grades. What does this look like? Why is it
                      important? Learn about social emotional awareness
                      strategies to help build community and awareness in the
                      9-15 age of development.
                    </p>
                  </div>
                </div>
                <hr />
                <div class="row">
                  <div class="col-md-4 ml-auto mr-auto">
                    <h4>Jennifer Spikner</h4>
                    <img
                      src="img/presenters24/Jennifer Spikner.jpg"
                      height="300px"
                      width="200px"
                    />
                  </div>
                  <div class="col-md-8 ml-auto mr-auto mt-auto mb-auto">
                    <p>
                      Jennifer Spikner is a Montessori teacher trainer, speaker,
                      and consultant with AMS credentials in Elementary I & II
                      and Administration. Her work focuses on authentic
                      Montessori practice, diversity, equity and inclusion and
                      social justice education.
                    </p>
                    <p>
                      Is access to clean water, land and air a fundamental human
                      right? What does social justice and equity have to do with
                      the environment and the legacy we will leave behind for
                      the next generation?
                    </p>
                  </div>
                </div>
                <hr />
                <div class="row">
                  <div class="col-md-4 ml-auto mr-auto">
                    <h4>Chip Reichanadter</h4>
                    <img
                      src="img/presenters24/Chip Reichanadter.JPG"
                      height="300px"
                      width="200px"
                    />
                  </div>
                  <div class="col-md-8 ml-auto mr-auto mt-auto mb-auto">
                    <p>
                      Chip Reichanadter, Founder and Teacher Leader of Pinyon
                      Montessori, leverages a decade of Montessori teaching
                      expertise, driven by a dedication to fostering liberatory
                      practices for educators and learners.
                    </p>
                    <p>
                      Discover the secrets of successful school grant writing in
                      this workshop. Learn the process, practical tips, and
                      explore sample proposals to unlock funding opportunities
                      for your educational projects.
                    </p>
                  </div>
                </div>
                <hr />
                <div class="row">
                  <div class="col-md-4 ml-auto mr-auto">
                    <h4>Kat Kirk & Shannon Foy</h4>
                    <img
                      src="img/presenters24/Kat Kirk and Shannon Foy.jpg"
                      height="300px"
                      width="200px"
                    />
                  </div>
                  <div class="col-md-8 ml-auto mr-auto mt-auto mb-auto">
                    <p>
                      Shannon Foy and Kat Kirk are experiential educators at the
                      Elizabeth Academy Upper School. They strive to foster
                      independence, inclusion, critical thinking, creativity,
                      and collaboration in their students in all of their
                      classes.
                    </p>
                    <p>
                      Explore the possibilities of teaching 21st Century Skills
                      through Microeconomy and Entrepreneurship. In this
                      workshop you will learn the steps to launching your own
                      Microeconomy program at your school and experience the
                      steps of Design Thinking first hand.
                    </p>
                  </div>
                </div>
              </collapse-item>
              <collapse-item title="WORKSHOP C: 3:15 - 4:15 PM" name="3">
                <div class="row">
                  <div class="col-md-4 ml-auto mr-auto">
                    <h4>Mercedes Castle</h4>
                    <img
                      src="img/presenters24/MercedesCastle.jpeg"
                      height="300px"
                      width="200px"
                    />
                  </div>
                  <div class="col-md-8 ml-auto mr-auto mt-auto mb-auto">
                    <p>
                      Mercedes Paine Castle, M.Ed. (Educational Leadership) is
                      an Assistant to Infancy (AMS), Montessori advocate, wife,
                      mother, and agent of change. She has been guiding toddlers
                      towards independence since 2003. Mercedes is a head of
                      school and teacher educator. Like Dr. Maria Montessori,
                      Mercedes believes that “The task of educators is immense
                      because human progress and world peace are in their hands”
                      (Montanaro, 1991, p. 165).
                    </p>
                    <p>
                      Let's look at our program goals and values - how do they
                      intersect for our tiniest learners? Where do we find the
                      evidence in our day to day practice that we are living our
                      values, and what does building peace look like if we focus
                      on one of the most important caregiving rituals -
                      mealtimes.
                    </p>
                  </div>
                </div>
                <hr />
                <div class="row">
                  <div class="col-md-4 ml-auto mr-auto">
                    <h4>Wyatt Tolles</h4>
                    <img
                      src="img/presenters24/Wyatt Tolles.jpg"
                      height="300px"
                      width="200px"
                    />
                  </div>
                  <div class="col-md-8 ml-auto mr-auto mt-auto mb-auto">
                    <p>
                      Wyatt Tolles has been teaching in a Montessori setting
                      since 2012. He completed his Early Childhood credential
                      and spent three years with this age level. Since 2015, he
                      has completed his ELI-II credential and taught age ranges
                      of 6-9, 9-12, and 6-12 for Acorn Montessori School in New
                      Jersey. In 2018, he joined the West Side Montessori TEP
                      and instructed for both ELI and ELII cohorts. Wyatt has
                      also been a supervising teacher for new teachers at his
                      school as well as a field consultant for interns.
                    </p>
                    <p>
                      Benchmarks, pacing guides, and assessment - oh my! Three
                      topics bound to create a healthy dialogue between
                      Montessori guides. In this presentation, we will look at
                      assessments through the lens of Montessori philosophy and
                      through the lens of the traditional education model. The
                      reflection of assessment bridges into the ideas of
                      educational benchmarks and pacing guides. In a pedagogy
                      underpinned by 'follow the child,' benchmarks and pacing
                      guides may seem counterintuitive. The presentation will
                      provide a process to maintain this philosophy while using
                      these tools. Lastly, we will look at how these tools can
                      increase communication, build more informed caregivers,
                      and bring equity to all children.
                    </p>
                  </div>
                </div>
                <hr />
                <div class="row">
                  <div class="col-md-4 ml-auto mr-auto">
                    <h4>Russ Duffield</h4>
                    <img
                      src="img/presenters24/Russ Duffield.jpg"
                      height="300px"
                      width="200px"
                    />
                  </div>
                  <div class="col-md-8 ml-auto mr-auto mt-auto mb-auto">
                    <p>
                      Russ Duffield is a founding member of the Dancing Moose
                      team. He has completed the coursework for Lower Elementary
                      from Rings of Knowledge and is a current Ph.D. student in
                      Educational Psychology focusing on Learning and Cognition
                      at the University of Utah. He is passionate about
                      understanding how individuals learn.
                    </p>
                    <p>
                      In the session, we will discuss current research outlining
                      how critical thinking is perceived and measured in the
                      classroom. We will also collaborate in a workshop format
                      to diagram lesson plans and discover how critical thinking
                      can be intentionally emphasized to encourage student
                      learning.
                    </p>
                  </div>
                </div>
                <hr />
                <div class="row">
                  <div class="col-md-4 ml-auto mr-auto">
                    <h4>Kat Kirk</h4>
                    <img
                      src="img/presenters24/Kat Kirk.jpg"
                      height="300px"
                      width="200px"
                    />
                  </div>
                  <div class="col-md-8 ml-auto mr-auto mt-auto mb-auto">
                    <p>
                      Kat Kirk is an Experiential Educator and works at the
                      Elizabeth Academy Upper School. She loves to engage
                      students in reflective learning activities and empower
                      them with skills for positive communication and
                      collaboration.
                    </p>
                    <p>
                      In this interactive workshop, you will get to learn and
                      participate in a sequence of Experiential Activities that
                      help create trust and connection in your school
                      communities and classrooms.
                    </p>
                  </div>
                </div>
                <hr />
                <div class="row">
                  <div class="col-md-4 ml-auto mr-auto">
                    <h4>Amanda Engebretsen</h4>
                    <img
                      src="img/presenters24/Amanda Engebretsen.jpg"
                      height="300px"
                      width="200px"
                    />
                  </div>
                  <div class="col-md-8 ml-auto mr-auto mt-auto mb-auto">
                    <p>
                      Amanda Engebretsen, CGMS infant/toddler certified ,
                      teaching for 4 years, working with children my whole life!
                      You can find me dancing in my car & singing in the shower.
                      Lets rock n roll together Montessori style!
                    </p>
                    <p>
                      "As the Montessori approach to music is based upon the
                      understanding that young children learn first through
                      their experiences and senses, we at once engage the ear,
                      the eye, the voice, the hand, the body, and the soul of
                      the child – the whole of the child is actively involved in
                      the entire musical experience."
                    </p>
                  </div>
                </div>
                <hr />
                <div class="row">
                  <div class="col-md-4 ml-auto mr-auto">
                    <h4>Kim Norman</h4>
                    <img
                      src="img/presenters24/Kim Norman.jpg"
                      height="300px"
                      width="200px"
                    />
                  </div>
                  <div class="col-md-8 ml-auto mr-auto mt-auto mb-auto">
                    <p>
                      Kim Norman is a mom and holds Montessori credentials at
                      the Early Childhood and Lower Elementary levels. She has
                      been teaching in Montessori settings for 17 years and
                      teaching in other settings for over 20 years. She is
                      currently teaching at The Elizabeth Academy Upper Campus
                      in the STEM department. She is a field consultant for The
                      Center for Guided Montessori Studies and enjoys mentoring
                      new Montessorians. She is passionate about trees, plants,
                      and her students.
                    </p>
                    <p>
                      As we inspire students to find their "Cosmic Task,"
                      Montessori's educational cornerstones of Cosmic and Peace
                      Education can guide us and our students to heal the Earth
                      and make peace with our planet.
                    </p>
                  </div>
                </div>
                <hr />
                <div class="row">
                  <div class="col-md-4 ml-auto mr-auto">
                    <h4>Leslie Woodford</h4>
                    <img
                      src="img/presenters24/Leslie Woodford.jpg"
                      height="300px"
                      width="200px"
                    />
                  </div>
                  <div class="col-md-8 ml-auto mr-auto mt-auto mb-auto">
                    <p>
                      Leslie Woodford, MA, is the Curriculum Coordinator at
                      Khalsa School in Tucson, AZ, an AMS emerging leaders
                      fellow, and an AMS educator of seven years (Admin,
                      Elementary I & II).
                    </p>
                    <p>
                      When it comes to racial issues, are you afraid you’ll make
                      mistakes or offend others. In this session, you’ll learn
                      the first steps to take to begin your ABAR journey.
                    </p>
                  </div>
                </div>
                <hr />
                <div class="row">
                  <div class="col-md-4 ml-auto mr-auto">
                    <h4>Kirsten Skirvin</h4>
                    <img
                      src="img/presenters24/Kirsten Skirvin.png"
                      height="300px"
                      width="200px"
                    />
                  </div>
                  <div class="col-md-8 ml-auto mr-auto mt-auto mb-auto">
                    <p>
                      Kirsten Skirvin: BS in psychology, Master Herbalist.
                      Garden Director DMMS 2 years, MACTE certified. Her journey
                      with the Montessori method began 33 years ago on a farm in
                      Nebraska.
                    </p>
                    <p>
                      The Edible Classroom presentation is for children to learn
                      the importance and joy of gardening and growing their own
                      food. How do we teach them the importance of where their
                      food comes from. Come find out!
                    </p>
                  </div>
                </div>
              </collapse-item>
            </collapse>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  Button,
  InfoSection,
  FormGroupInput,
  Collapse,
  CollapseItem,
} from "@/components";

export default {
  name: "umc-conference",
  bodyClass: "umc-conference",
  components: {
    InfoSection,
    [Button.name]: Button,
    [FormGroupInput.name]: FormGroupInput,
    Collapse,
    CollapseItem,
  },

  data() {
    return {
      rows: [],
    };
  },
  metaInfo: {
    title: "Utah Montessori - UMC Conference",
    meta: [{ name: "description", content: "Presenters Bios" }],
  },
};
</script>
<style>
.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 75%;
  margin-top: 50px;
  margin-bottom: 20px;
}
</style>
