<template>
  <div class="wrapper blog-post">
    <div class="page-header page-header-small">
      <parallax
        class="page-header-image"
        style="background-image: url('img/blog/rich.jpg')"
      ></parallax>
      <div class="content-center">
        <div class="row">
          <div class="col-md-8 ml-auto mr-auto text-center">
            <h2 class="title">The Jewel of a School</h2>
            <h4>Infant/Toddler Faculty at Center for Guided Montessori Studies</h4>
          </div>
        </div>
      </div>
    </div>
    <div class="section">
      <div class="container">
        <div class="row">
          <div class="col-md-10 ml-auto mr-auto">
            <h3 class="title">The Jewel of a School</h3>
            <p>An infant kicks excitedly at a mobile hanging overhead. A seated child reaches into a treasure basket to
              grasp a soft bristled brush. A toddler tries with all her might to lift a pitcher to pour her own juice... while
              another kneads dough for baking daily bread. These are a few of the things you might see if you peek into a
              Montessori infant or toddler environment.</p>
            <p>Dr. Maria Montessori dedicated over half of the Absorbent Mind to the development of infants and
              toddlers, and lectured extensively on the earliest years of life. She realized that development is not
              complete when the child is born, and that the continued formation of the mind and body is literally
              dependent on the environment surrounding the child. Children need an opportunity to internalize the
              rhythms of healthy daily living. They must first form secure attachments and develop capacities for
              organizing the bombardment of new impressions and information, as well as the ability to coordinate and
              control their bodies. They must begin to process emotions and to use language to navigate the social
              world. Dr. Montessori was one of the first to realize the critical importance of the early years as the
              physiological foundation for lifelong mental and physical health (that the child is literally in the process of
              building the person they will become).</p>
            <p>Unfortunately, care during this most delicate period is often passed off as simply “changing diapers,” and it
              is sometimes assumed that there is no special need for training. Nothing could be further from the truth.
              This assumption can be an obstacle to optimal development, and can also cause undue stress for both the
              child and the adult caregiver. When a child is nonverbal, keen observation skills and a deep knowledge of
              human development is especially important. Adults must possess a high level of self awareness in
              response to the many challenges of sharing the day with young learners. Creating community life, while
              respecting the needs of each individual (as they grow and change) is an art ... as well a science.
              This is similar to fashioning a healthy family life, and parents who decide to stay at home with their children
              need the traditional support of a caring community. Parents of children prenatal to three are often very
              receptive, and willing, to accept guidance (a "sensitive period," if you will). This seems to be equally true for
              at-risk parents as it is for highly powered executives. Weekly sessions for parents and their babies can
              introduce stay-at-home moms, nannies, or grandparents to Montessori principles and practices. This
              allows families to incorporate Montessori into their homes ...in such a way that benefits the child for their
              entire lifetime (rather than just the few short years while they are in our classrooms). Our hope is also that
              parents who start when their children are infants and toddlers will be more likely to enroll their children at
              the older levels, and remain at the school longer.</p>
            <p>Dr. Montessori wanted babies with their parents whenever possible, however for many families, staying
              home with their children is not an option. In this case, our prepared environments can provide a viable
              alternative that can allow parents the peace of mind to do their jobs well and diminish stress, while offering
              children a nurturing learning community that is able to beautifully meet their needs.
              It is for all these reasons that some say the quality of a Montessori school can be assessed by their
              commitment to infant and toddler programs. If Montessori pedagogy is truly understood as an aid to life
              (optimal physiological and neurological development) then supporting this process from birth must form its
              foundation. Quality resources provided during early sensitive periods are well spent, and their attributes
              “absorbed” into the interior life of the child through interaction and relationships. This is why we hope the
              environments for the youngest among us would be the “jewel of any school.”</p>
            <h4>The Child is the Curriculum</h4>

            <p>“Can you feel that your shirt is wet? Let’s change into a dry one. Can you slide your arm out of this sleeve
              ...and now this one too? Now we are ready to pull the wet shirt over your head.” While many in the
              community at large debate the need for offering quality childcare and early learning (even at the
              infant/toddler level), the focus is almost always on abstract concepts-- such as the names of wild animals,
              counting, or the ABC’s. Some in the Montessori community attempt to offer smaller versions of materials
              for the 3 to 6 classroom to very young children. Even topics such as social skills are “taught” through
              stories or lessons. But these approaches overlook the rich opportunities for learning through lived
              experience and connections with other people. Dressing and undressing is the perfect backdrop for
              introducing prepositions such as “over,” “under,” and “through”.... as well as adjectives such as “wet” and
              “dry.” As the shirt touches the skin, signals are sent to the brain that become associated with
              simultaneously spoken words...and these impressions (and neural pathways) are again strengthened when
              accompanied by the pleasant emotions of being engaged in shared tasks with a person who loves and
              cares for you.</p>
            <p>We must always keep in mind that the child is not “learning” as much as they are actually developing the
              physiological systems needed to perceive and interact with their surroundings with acuity and accuracy.
              And, the child who is new to the world must begin at the beginning. Infants and toddlers are driven to seek
              out experiences that will aid the formation of balance, coordination, and awareness of their bodies in time
              and space (also called proprioception) through movement and repetition. They also “learn” to see and hear
              in response to their perceptual experiences (neurons are developed based on the quality of exposure and
              repetition of sights and sounds). Development of memory and executive functions rely on the ability to
              anticipate consistent patterns of events (if/then relationships) ...and so on. These experiences during
              sensitive periods will form the foundation, or cognitive architecture, that the child will use to process and
              learn the information they will encounter in the future.</p>
            <p>A focus on “teaching” concepts outside of the child’s experience puts the cart before the horse. Dr.
              Montessori writes in the Absorbent Mind, “When we understand that the energies belong to an
              unconscious mind, which has to become conscious through work and through an experience of life gained
              in the world, we realize that the mind of a child in infancy is different from ours, that we cannot reach it by
              verbal instruction, nor intervene directly in the process of its passing from unconscious to conscious -the
              process of making human faculty. Then the whole process ...becomes a matter of giving help to the child’s
              life, to the psychological development of man.” She adds, “At no other stage has the child a greater need
              of an intelligent help.” A child will take in everything, and weave into character “whatever he finds about
              him.”</p>
            <p>Therefore, we must carefully create environments that will meet the child’s biologically “evolved
              expectations” for care. Young children are primed to learn through “pitching in” with the exercises of daily
              practical life. Adults can invite children to participate in their personal care, care of the environment, and
              care of others. Adults must speak slowly, articulating their words carefully, and also break down complex
              movements into smaller discrete components. They must use empathetic gestures that convey kindness
              and acceptance, and provide nonverbal messages to help a child anticipate transitions or the next steps in
              a process. Children watch the adult’s actions both for communication and in order to imitate them. As the
              child becomes able to take over small steps, the adult recedes and offers only as much help as the child
              needs in order to be successful.</p>

            <p>Adults at this level are also encouraged to model choosing work in all areas of the classroom
              (sensorimotor, art, language, practical life) instead of giving formal lessons to young children (with few
              exceptions). In this way, they can encourage the child’s discovery and exploration, and also establish care
              in holding and using materials. Engaging in activities themselves also allows the adult to create consistent
              routines, and to include things such as regular yoga practice, gardening, playing musical instruments,
              reading, handcrafts, and other interests (normalizing their occurrence and expanding the child’s awareness
              of ways for self expression). Children learn through observation, and use indirect opportunities to
              coordinate their movements and to direct their will. Over time, the child moves from an explorer and a
              joiner ...to completing entire work cycles independently. This is often a sign that they are getting ready to
              move to the Children’s House.</p>
            <h4>Teacher Preparation</h4>
            <p>As advances in cognitive and neuroscience continue to show the importance of the first years of life, there
              has been an increasing understanding of the value of this work and the adults who possess talent for
              working with this age group. Hopefully, more and more highly educated people will see this as a viable
              profession. Currently, there is an surge in training programs, and a variety of training options.
              Teacher preparation courses must include an in-depth knowledge of human development from prenatal to
              age three. Studies should also include the interdisciplinary skills of observation, assessment, child
              development theories, spiritual preparation of the adult (self-awareness, self-regulation, emotional
              intelligence, ethics, etc.), philosophy, nutrition, Montessori pedagogy, and how to move from principles ...
              to authentic practice (both at home and at school).</p>

            <p>Students can acquire training from accredited summer programs (with full year practicums and exams
              following); or in programs that operate over weekends during the practicum. Some universities offer
              courses as part of an advanced Montessori degree. There are also a handful of online Montessori
              courses, although MACTE will only accredit those that include a face to face component as well. The
              Center for Guided Montessori Studies offers an all-of-the-above approach, with a summer residential
              session, visits from field consultants,, weekly real time video classes led by Instructional Guides, discussion
              forum participation within a stable cohort of classmates, and ongoing online video presentations and
              assignments throughout their practicums.</p>
            <p>While the CGMS Infant Toddler Course is new, there has already been some encouraging feedback. For
              example, it has been reported that the format creates a more balanced discussion when compared to a
              classroom setting (where there are both chatty and quiet communicators). The slower 18 month pace
              allows for more time to process and integrate the material. Students consistently check out of video chats
              with the word"connected" and talk about how it supports them in staying engaged and on track. They also
              take advantage of the video format, often repeating videos several times to deepen their understanding of
              the content, something that is not available to those learning via in-class lectures. Distance learning also
              allows an individual to strategically place the infant/toddler coursework into their life. Student may
              construct and alter their class schedule according to their lifestyle because, whether one is a night owl or
              morning lark, the online course is always open and available ... so interns can choose when to study and
              work on assignments - and this is a huge benefit. Students can also contribute from a variety of locations.
              It is not unusual for students from different countries to share information on family life, customs, and
              transportation - as well as obstacles and challenges that can be thought provoking and illuminating. Interns commonly join chat from their sofa or desk at home as family life happens in the background. Alternately, members can participate from a coffee shop, hotel, or the great outdoors Ç wherever internet or phone
              service is available.</p>

            <p>There are pros and cons to any delivery of Montessori training. Costs, distance, quality of experience, and
              the needs of individual learners must be considered. We encourage careful research in making decisions
              about how to best prepare a person to assist the development of infants and toddlers in Montessori
              prepared environments (and to support parents in their important role as well).</p>

            <h4>Conclusion</h4>
            <p>As new innovations and instruments of measure continue to confirm Dr. Montessori’s work and model of
              education, we must prepare our schools to meet a new and growing public interest. We know that
              education begins at birth, so where ever possible we must support parents and offer them needed
              services.</p>
            <p>Maria Montessori asked us to become like doctors offering the correct dosage of a prescription - using our
              knowledge of human development and the individual child, to align our actions with the multidimensional
              needs (physical, cognitive, emotional, social, spiritual) of every child in our care. Montessori also said that
              we must become "love teachers" - models of empathy, acceptance, and respect. We realize that we have a
              special obligation to protect and nurture the "seed of potential" (the secret of childhood) within each child.
              It is on this firm foundation that all future learning and experiences will be built. It is through this beginning
              that we see a greater hope for the future of humanity ... and the full realization of human potential.
              This is why our infant and toddler communities are so much more than diaper changing and childcare
              facilities. This is why they must be given all the resources needed to be considered the “jewel of your
              school.”</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Card, Button, Comment, Badge, InfoSection, Checkbox } from '@/components';

export default {
  name: 'rich-environment',
  bodyClass: 'blog-post',
  components: {
    Card,
    InfoSection,
    Badge,
    Comment,
    [Button.name]: Button,
    [Checkbox.name]: Checkbox
  },
  metaInfo: {
    title: 'Utah Montessori - The Jewel of a School',
    meta: [
      { name: 'description', content: 'An infant kicks excitedly at a mobile hanging overhead. A seated child reaches into a treasure basket to grasp a soft bristled brush. A toddler tries with all her might to lift a pitcher to pour her own juice... while another kneads dough for baking daily bread. These are a few of the things you might see if you peek into a Montessori infant or toddler environment...' }
    ]
  }
}
</script>
<style>
</style>
