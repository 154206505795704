<template>
  <div class="wrapper blog-post">
    <div class="page-header page-header-small">
      <parallax
        class="page-header-image"
        style="background-image: url('img/blog/rich.jpg')"
      ></parallax>
      <div class="content-center">
        <div class="row">
          <div class="col-md-8 ml-auto mr-auto text-center">
            <h2 class="title">Our Pandemic Experience</h2>
            <h4>Duna Strachan, BS AMS MEd</h4>
            <h5>Written for Montessori Life, a journal of the International Montessori Council, in response to a request for our experience so far.</h5>

          </div>
        </div>
      </div>
    </div>
    <div class="section">
      <div class="container">
        <div class="row">
          <div class="col-md-10 ml-auto mr-auto">
            <h3 class="title">Our Pandemic Experience</h3>
            <p>
              The sunshine filters in across the glistening beads as a student counts them carefully. Another student is on the patio applying strokes of paint to a new design. Two more work in the garden, and several sit at separate tables, intent on their work. It is a typical day in an early childhood class, except that these children are wearing face masks. It is July 2020 and we are in the depths of the COVID19 Pandemic.
            </p>
            <p>At our school in Park City, Utah we were shut down for ten weeks through the spring. Teachers worked industriously to provide work for students at home during the shutdown, but we weren’t sure that our students, who are mostly under the age of 6, got as much out of the packets, emails, slide shows, video links, Zoom meetings and Google Classrooms as we had hoped. So we were determined to offer what they needed most – time in our prepared environment.</p>
            <p>Our whole faculty spent the ten weeks of shutdown planning carefully. Through a series of emails, Zoom meetings, phone calls, Google docs and texts we began to sort out how to proceed. Not only were there new protocols to adopt, but also new employment considerations, government hoops to jump through and all the discussions on how to maintain Montessori philosophy and pedagogy under such dire circumstances. Our local and global Montessori community provided a daily influx of support and ideas. But between our small faculty and administration team, it was our task to sort out the useful items and determine how best to apply them.</p>
            <p>Our School Director, Leah Linebarger, got right to work with the local health department on a task force focusing on establishing new protocols during the pandemic. This provided the groundwork for how we would proceed. Our Administrator, Bruce King, found sorting the details of employment law a full-time job as he diligently searched for the best ways to support our faculty. Our Utah Montessori Council (UMC) shared precious documents, websites and platforms as each school crafted our path forward. UMC Administrator Teas moved from once each month elegant tea parties to twice per month, sleeves-rolled-up, Zoom support meetings. We found we gained significant insight from other heads of schools from across our state as we shared ideas and learned from each other. Two of the schools, Anchored Roots and Dancing Moose Montessori Schools, had stayed open through the shutdown and their experience was invaluable as we headed into the unknown.</p>
            <p>After months without income and missing the culminating events of the year – Closing Ceremonies, our school fair and our faculty end of the year party – the school year ended not with a bang, but a whimper. Teachers provided moving Zoom, video and drive-by graduations for their classes. Families filled in where we could not, with hugs, flowers, balloons, and treats. At that point we were not sure what to expect for the future of our school. But we knew that we had to provide normalcy for the children. We knew we had to support the families. We knew we had to get back into the classroom.</p>
            <p>After wrestling with all of these plans, ideas and endless checklists we cautiously opened our school again on June 1st. We simplified rosters so we could begin with just 10 students per class. Students attended only for the mornings for the first week while we smoothed out the wrinkles. Teachers simplified materials so children could choose their work, return it to a cleaning shelf and an adult could sanitize it and return it to its place in the classroom. There were fewer choices, but we were reminded of Montessori researcher, Angeline Lillard’s (2012) words on keeping materials to the most necessary. She found in comparing classes with classic Montessori materials and well-trained teachers to those with Montessori-ish materials and methods, the students in the authentic Montessori classrooms developed greater concentration, retained concepts more readilly and enjoyed the learning experience more than those in the Montessori-ish environments. We found that not only were children concentrating more, but having fewer materials on the shelf ensured that all were sparkling clean, complete and orderly at all times. Teachers rotated materials to meet student needs. Our classrooms looked like the photos of Montessori’s original classrooms with each child focusing on the simple, classic material before them.</p>
            <p>We simplified and expanded drop-off and pick-up so classes do not mix and parents are not coming into the building. Faculty, students and caregivers are having their temperatures and symptoms checked at the exterior classroom door at drop-off. We discontinued our snack and lunch programs and asked parents to provide these. Although it hurt to eliminate the classic community exercises of meal preparation, table setting, serving one another and dish washing, this greatly simplified our daily preparation process and allowed time for the additional sanitizing. </p>
            <p>Sanitizing everything in the room twice a day was what we worried most about until one of our comrades at another school discovered that isopropyl alcohol can be used. It is simply sprayed on and allowed to evaporate. Alcohol is hard on wood, so we use soap and water for our wooden equipment. We have tried to substitute plastic materials wherever possible to save our wood.
            </p>
            <p>Acquiring enough thermometers, gloves, masks, smocks, hand sanitizer, hand soap, paper cups, paper plates and paper place mats is an ongoing task. Teachers created individual packets of crayons, pencils, scissors, glue sticks and paper for early childhood students so those things did not need to be individually sanitized after each use. Maintaining all this necessary inventory is probably the biggest ongoing expense. And this on top of the greatly reduced income through the 10 weeks of closure. We are all taking a significant financial hit. But as we have learned through the recessions, all we have to do is cut back, pull together and it will come around again. Eventually.</p>
            <p>We’ve had to eliminate assemblies, guest speakers and school events, but this results in a simpler routine and less interruptions to the work period. We avoid mixing classes, but this makes the prep and cleaning schedule simpler when each teaching team is taking care of their own classroom instead of relying on someone to come in to help them. With the simplification of so much of our day, teaching teams have more time to maintain their own environments.</p>
            <p>Masks were a daunting prospect. We started off recommending that students wear masks as a sign of respect for others, and many did. Then our town adopted a mask mandate and our students cheerfully went along with it. They were much more amenable to it than most adults have been. Now all early childhood and elementary students wear masks into and out of school and while inside unless they can stay 6 feet apart. Early childhood and elementary students have assigned work tables or mats so there is less mixing within the classroom. When lining up they space themselves a healthy distance, or an arm’s length, from the person in front of them. Outside they are free to play as they always have, but teachers may remind them to give each other healthy space. And we spray all equipment with alcohol between classes. </p>
            <p>Other than the temperature and symptom checks at drop-off, increased cleaning routines and the smaller class sizes, the infant and toddler school days are essentially unchanged. Teaching teams are careful to sanitize work after each use, wear masks and gloves and infant faculty wears lab coats or smocks as well. All students have always washed hands multiple times per day and more so when there is illness about, so this routine is unchanged.</p>
            <p>Although we are respectful of each other and generally stay 6 feet apart from one another, teachers are very sensitive to their students’ needs and give hugs and physical reassurance whenever appropriate. It is daunting enough that we cannot see each other’s expressions, we can at least give a child a hug. </p>
            <p>After the months of planning and preparation with our teaching teams, a few of our teachers did not feel comfortable about coming back to school during a pandemic and we honor their decisions. You have to want to do this. It is a risk to be at school, interacting with students, families and colleagues every day, even with a masks, gloves and smocks. In order to offer the extension for our students we again called on our Montessori community, borrowed teachers from other schools and hired a few who consider the risk worthwhile to give these children what their families can’t – a rich, learning environment, where students are free to make their own decisions and develop their confidence, executive functions and social skills.</p>
            <p>During the closure the families in our school stepped forward to offer funds for our faculty whose income dropped precipitously when their spouses and roommates lost their jobs. Parents collected funds and brought groceries and surprises to teachers’ doorsteps. Some families decided to wait to feel more comfortable before returning to school. Enrollment dropped and then, we began to get calls from families looking to move to our little mountain town from the big cities. The rosters are filling again, and we have some eager new employees enthusiastic about all things Montessori.
            </p>
            <p>We have found that the children are generally better at adapting to these changes than we adults. They don’t understand what or why coronavirus is, but they understand that we have to help each other to stay healthy and safe by wearing masks, washing hands, giving healthy space and being careful of what they touch. Lunch is different, group lessons are different and coming in and out of school is different. But most of what goes on in between is pretty much the same. And for that normalcy we are ever so grateful. When I stand at the gate and greet families each morning, I accept loads of thanks and praise. One day in early June it snowed in our little mountain town. But there were our families, having scrambled to find their winter wear as well as their face masks, lining up at the orange cones placed 6 feet apart at each door and still smiling and giving thanks for being able to bring their children to school for a few hours. We feel like this trying time has gone so smoothly because of the support of our incredible families.</p>
            <p>Since it is summer, we’ve spent much more time outside, making use of our patios, gardens, playground, creek and field like we’ve never used them before. We’ve taken time outside to observe native plants and animals. Most of the children in our school now know the call of the Uintah Ground Squirrel and where the voles and garter snake live. They know where to find strawberries, chives, honeysuckle and the lavender that can be used in all types of Practical Life projects. They have discovered the beauty of our campus that is so often missed in the summer when our student population normally falls off.</p>
            <p>Winter will bring more problems to solve – where to store snow clothes so children can access them without coming into contact with children from other classes, lunching in a social fashion, but at a healthy distance, how to keep classes adequately staffed when winter illnesses make their rounds. And there will be questions we can’t even foresee now. But we have an incredibly diligent and committed community to help us figure things out.</p>
            <p>Early on in the pandemic I thought about how few world crises we have experienced in the past few decades. Those of us of a certain age lived through polio, the Cuban Missile Crisis, Viet Nam, AIDS, Gulf War, September 11th, Iraq War, the War in Afghanistan, mass shootings and a plethora of environmental crises. Our parents and grandparents told us about the World Wars, Pearl Harbor Day, the Spanish flu, scarlett fever and smallpox. An elderly cousin of mine recently remarked that Americans were on rations for four years after WWII – in a tone that made me think, “we ain’t seen nothin’ yet.” It could be worse. We have examples before us of how to behave during a crisis. Following our ancestors’ lessons, we will look around, lend a hand to those who need it, summon our courage, face down our fears and step into the fray to contribute whatever we can. As we face each challenge, we have to remind ourselves that the entire population of the world is in distress right now. But within each of us is the ability to lighten another’s load with a smile, a few words, an act of kindness. This is the time to fight this good fight with great honor.</p>
            <p>We will not be the same school again. We will not be the same people again. But along this journey we have found the beauty in simplifying, in enjoying what we can do together and the joy of returning to the classroom where both children and adults are getting something very important. When we recall the COVID years we will remember the lessons learned that we will still carry with us. We are learning to slow down, focus on the moment and support one another fully.</p>
            <h3 class="title">References</h3>
            <p>Lillard, A. S. (2012). Preschool children&#39;s development in classic Montessori, supplemented
              Montessori, and conventional programs.</p>
            <p>Journal of school psychology, 50(3), 379–401.</p>
            <p>https://doi.org/10.1016/j.jsp.2012.01.001</p>
            <p>Duna Strachan, BS AMS MEd, is the founder of Soaring Wings International Montessori School
              in Park City, Utah. She has been involved in Montessori for 35 years and holds credentials at the
              infant/toddler and early childhood levels. She is also the founder of the Utah Montessori Council,
              adjunct faculty at several teacher education colleges and a local, national and international
              consultant.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Card, Button, Comment, Badge, InfoSection, Checkbox } from '@/components';

export default {
  name: 'rich-environment',
  bodyClass: 'blog-post',
  components: {
    Card,
    InfoSection,
    Badge,
    Comment,
    [Button.name]: Button,
    [Checkbox.name]: Checkbox
  },
  metaInfo: {
    title: 'Utah Montessori - Our Pandemic Experience',
    meta: [
      { name: 'description', content: 'The sunshine filters in across the glistening beads as a student counts them carefully. Another student is on the patio applying strokes of paint to a new design. Two more work in the garden, and several sit at separate tables, intent on their work. It is a typical day in an early childhood class, except that these children are wearing face masks. It is July 2020 and we are in the depths of the COVID19 Pandemic.' }
    ]
  }
}
</script>
<style>
</style>
