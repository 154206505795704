<template>
  <div class="wrapper blog-post">
    <div class="page-header page-header-small">
      <parallax
        class="page-header-image"
        style="background-image: url('img/home/utah.jpg')"
      ></parallax>
      <div class="content-center">
        <div class="row">
          <div class="col-md-8 ml-auto mr-auto text-center">
            <h2 class="title">Our UMC Members</h2>
            <h4>Join our great list of schools!</h4>
          </div>
        </div>
      </div>
    </div>
    <div class="section">
      <div class="container">
        <div class="row">
          <div class="col-md-10 ml-auto mr-auto">
            <h3 class="title">Member Schools</h3>
            <p>
              Membership is open to all; you don’t need to be a Montessorian or
              a Montessori school. All you have to do is complete the one page
              application and agree to abide by the Code of Ethics and it’s
              free!
            </p>
            <div class="row justify-content-center">
              <div
                class="col-md-5 ml-1"
                v-for="(school, index) in schools"
                :key="index"
              >
                <card type="profile" plain>
                  <img
                    v-if="school.logo"
                    slot="image"
                    class="card-img-top"
                    :alt="school.name"
                    :src="school.logo"
                  />
                  <img
                    v-else
                    slot="image"
                    class="card-img-top"
                    :alt="school.name"
                    src="https://via.placeholder.com/320x180.png"
                  />
                  <div>
                    <h4 class="card-title">{{ school.name }}</h4>
                    <h6 class="card-subtitle mb-2 text-muted">
                      {{ school.location }}
                    </h6>
                    <a
                      v-if="school.website"
                      :href="school.website"
                      class="btn btn-primary"
                      target="_blank"
                      ><strong>Visit Website</strong></a
                    >
                    <p v-else>{{ school.email }}</p>
                  </div>
                </card>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  Card,
  Button,
  Comment,
  Badge,
  InfoSection,
  Checkbox,
} from "@/components";

export default {
  name: "member-schools",
  bodyClass: "member-schools",
  data() {
    return {
      schools: [
        {
          name: "Age of Montessori",
          location: "Bozeman, MT",
          website: "http://ageofmontessori.org/",
        },
        {
          name: "Bell Canyon Montessori School",
          location: "Sandy, UT",
          website: "https://bellcanyonmontessori.com/",
          logo: "https://img1.wsimg.com/isteam/ip/44256aa5-d62d-4d5b-b464-aa54416940ee/logo/f9b304eb-e4bb-4af2-9b8c-01d69caa3469.png/:/rs=h:320/qt=q:95",
        },
        {
          name: "Blooming Minds Montessori School",
          location: "South Jordan, UT",
          website: "https://www.bloomingmindsmontessori.com/",
          logo: "https://static1.squarespace.com/static/58b896be893fc0ddb1a8f324/t/58eeb1c1e4fcb501940c4278/1571253737848/?format=1500w",
        },
        {
          name: "Cadalin Montessori School",
          location: "",
          website: "http://www.cadalin.com/index.php",
        },
        {
          name: "Dancing Moose Montessori School",
          location: "Salt Lake City, UT",
          website: "http://www.mydancingmoose.com/",
          logo: "https://mydancingmoose.com/wp-content/uploads/2019/09/Dancing-Moose-Logo.png",
        },
        {
          name: "Discovery Academy Montessori School",
          location: "Bountiful, UT",
          website: "https://ourmontessori.org/",
        },
        {
          name: "Dixie Montessori Academy",
          location: "Washington, UT",
          website: "https://dixiemontessoriacademy.org/",
        },
        {
          name: "Earth Child Montessori School",
          location: "Richmond, BC, Canada",
          website: "https://ourmontessori.org/",
        },
        {
          name: "Elizabeth Academy",
          location: "Salt Lake City, UT",
          website: "https://www.elizabethacademy.com/",
          logo: "img/ea.jpg",
        },
        {
          name: "Gateway Preparatory Academy",
          location: "Enoch, UT",
          website: "https://gpacharter.org/",
        },
        {
          name: "Grasslands Montessori Academy",
          location: "Dickinson, ND",
          email: "Office@ndgma.com",
        },
        {
          name: "Growing Light Montessori School",
          location: "Midway, UT",
          website: "http://www.growinglight.org/",
        },
        {
          name: "Ignite Entrepreneurship Academy",
          location: "Lehi, UT",
          website: "https://www.igniteutah.org/",
        },
        {
          name: "Joyful Learning Montessori",
          location: "Salt Lake City, UT",
          website: "https://www.joyfullearningmontessori.com/",
        },
        {
          name: "Leap Ahead Montessori",
          location: "Pleasant Grove, UT",
          website: "https://www.facebook.com/leapaheadmontessori/",
        },
        {
          name: "Lighthouse Montessori Academy",
          location: "Provo, UT",
          website: "http://www.lighthousemontessoriacademy.com/",
        },
        {
          name: "Little Miners Montessori School",
          location: "Park City, UT",
          website: "http://www.littleminers.org/",
          logo: "https://littleminers.org/wp-content/uploads/2019/03/little-miners-montessori-logo-1024x453.png",
        },
        {
          name: "Maria Montessori Academy",
          location: "North Ogden, UT",
          website: "http://www.mariamontessoriacademy.org/",
        },
        {
          name: "Montessori @ Riverton",
          location: "Riverton, UT",
          website: "http://ourjourney.org/",
          logo: "http://static1.squarespace.com/static/5480b60ae4b0787f2c735450/t/5e1e54edd6399b3a2450bb20/1579047823626/?format=750w",
        },
        {
          name: "Montessori Canyon Academy",
          location: "Alpine, UT",
          website: "http://www.montessoricanyonacademy.com/",
        },
        {
          name: "Montessori Community School",
          location: "Salt Lake City, UT",
          website: "http://www.montessorislc.com/",
          logo: "https://mcsslc.com/images/logo2.png",
        },
        {
          name: "Mountain West Montessori Academy",
          location: "South Jordan, UT",
          website: "http://www.mwmacademy.org/",
          logo: "https://mwmacademy.org/assets/mwma-logo-7b154d9af98bdb7970c4fe4f2cfeb348ff3491bb901f4c4a179950e646d2c88a.png",
        },
        {
          name: "Nahunta Hall",
          location: "Lindon, UT",
          website: "http://www.nahuntahall.org/",
        },
        {
          name: "New Discovery Montessori",
          location: "Washington Terrace, UT",
          website: "http://www.newdiscoverymontessori.com/",
          logo: "http://www.newdiscoverymontessori.com/images/WebsiteLogo2.jpg",
        },
        {
          name: "Pocatello Valley Montessori School",
          location: "Pocatello, ID",
          website: "http://www.pocatellovalleymontessori.org/",
        },
        {
          name: "Richard McKenna Montessori School",
          location: "Mountain Home, ID",
          website: "http://www.rmckenna.org/",
        },
        {
          name: "Snake River Montessori School",
          location: "Ammon, ID",
          website: "http://www.srms.org/",
          logo: "http://www.srms.org/pics/00_logo.jpg",
        },
        {
          name: "Soaring Wings International Montessori School",
          location: "Park City, UT",
          website: "http://www.soaringwings.org/",
          logo: "img/swims.png",
        },
        {
          name: "South Jordan Montessori",
          location: "South Jordan, UT",
          website: "http://southjordanmontessori.com/",
        },
        {
          name: "Stepping Stones in Kamas",
          location: "Kamas, UT",
          website: "https://www.steppingstonesinkamas.com/",
        },
        {
          name: "Thriving Day Montessori Academy",
          location: "Heber, UT",
          website: "https://www.facebook.com/thrivingday/",
        },
        {
          name: "University of Utah Child & Family Development Center",
          location: "Salt Lake City, UT",
          website: "http://fcs.utah.edu/cfdc/",
        },
        {
          name: "Walden School",
          location: "Provo, UT",
          website: "http://www.waldenschool.us/",
        },
        {
          name: "Willow Creek Montessori",
          location: "Cottonwood Heights, UT",
          website: "http://willowcreekmontessori.com/",
          logo: "https://www.willowcreekmontessori.com/wp-content/uploads/2019/07/Willow_Creek_Gear_Logo-e1469714220878.jpg",
        },
        {
          name: "World Vantage Montessori School of Suzhou",
          location: "Suzhou",
          website: "http://www.vwmschina.com/",
        },
      ],
    };
  },
  components: {
    Card,
    InfoSection,
    Badge,
    Comment,
    [Button.name]: Button,
    [Checkbox.name]: Checkbox,
  },
  metaInfo: {
    title: "Utah Montessori - Member Schools",
    meta: [{ name: "description", content: "Join our great list of schools!" }],
  },
};
</script>
<style></style>
