<template>
  <div class="wrapper council-of-elders">
    <div class="page-header page-header-small">
      <parallax
        class="page-header-image"
        style="background-image: url('img/home/utah.jpg')"
      ></parallax>
      <div class="content-center">
        <div class="row">
          <div class="col-md-8 ml-auto mr-auto text-center">
            <h2 class="title">Council of Elders</h2>
            <h4>The ones who started it all!</h4>
          </div>
        </div>
      </div>
    </div>
    <div class="section">
      <div class="container">
        <div class="row">
          <div class="col-md-10 ml-auto mr-auto">
            <h3 class="title">Council of Elders</h3>
            <p>The UMC Council of Elders are Utah Montessorians who have demonstrated a lifetime of commitment to the philosophy and principles espoused by Maria Montessori. The Elders aspire to nurture the ideals of Montessori and to advocate for and mentor those who strive to respect and honor the child.</p>
            <div class="row justify-content-center">
              <div class="col-md-5 ml-1">
                <card
                  type="profile"
                  plain
                >
                  <img
                    slot="avatar"
                    class="img img-raised"
                    src="img/elders/lois.png"
                  />

                  <h3 class="card-title">Lois Bobo</h3>
                  <h6 class="category text-primary">AMS, PhD</h6>
                  <p class="card-description">
                    lbobo@wgu.edu
                  </p>
                </card>
              </div>
              <div class="col-md-5 ml-1">
                <card
                  type="profile"
                  plain
                >
                  <img
                    slot="avatar"
                    class="img img-raised"
                    src="img/elders/default.png"
                  />

                  <h3 class="card-title">Carrol Bybee</h3>
                  <p class="card-description">
                    l.bybee@comcast.net
                  </p>
                </card>
              </div>
              <div class="col-md-5 ml-1">
                <card
                  type="profile"
                  plain
                >
                  <img
                    slot="avatar"
                    class="img img-raised"
                    src="img/elders/default.png"
                  />

                  <h3 class="card-title">Robyn Eriwata-Buchanan</h3>
                  <p class="card-description">
                    robyneriwata@montessorislc.com
                  </p>
                </card>
              </div>
              <div class="col-md-5 ml-1">
                <card
                  type="profile"
                  plain
                >
                  <img
                    slot="avatar"
                    class="img img-raised"
                    src="img/elders/nancy.jpg"
                  />

                  <h3 class="card-title">Nancy Lindeman</h3>
                  <h6 class="category text-primary">MA</h6>
                  <p class="card-description">
                    nlindeman@westminstercollege.edu
                  </p>
                  <router-link
                    tag="a"
                    class="btn btn-primary"
                    :to="{name: 'nancy-lindeman'}"
                  ><strong>Learn More</strong>
                  </router-link>
                </card>
              </div>
              <div class="col-md-5 ml-1">
                <card
                  type="profile"
                  plain
                >
                  <img
                    slot="avatar"
                    class="img img-raised"
                    src="img/elders/duna.jpg"
                  />

                  <h3 class="card-title">Duna Strachan</h3>
                  <h6 class="category text-primary">BS, AMS, MEd</h6>
                  <p class="card-description">
                    duna@soaringwings.org
                  </p>
                  <router-link
                    tag="a"
                    class="btn btn-primary"
                    :to="{name: 'duna-strachan'}"
                  ><strong>Learn More</strong>
                  </router-link>
                </card>
              </div>
              <div class="col-md-5 ml-1">
                <card
                  type="profile"
                  plain
                >
                  <img
                    slot="avatar"
                    class="img img-raised"
                    src="img/elders/default.png"
                  />

                  <h3 class="card-title">Julie Wand</h3>
                  <p class="card-description">

                  </p>
                </card>
              </div>
            </div>
            <p class="text-center">We are here to support all UMC members.
              Please do not hesitate to contact any of us.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Card, Button, Comment, Badge, InfoSection, Checkbox } from '@/components';

export default {
  name: 'council-of-elders',
  bodyClass: 'council-of-elders',
  components: {
    Card,
    InfoSection,
    Badge,
    Comment,
    [Button.name]: Button,
    [Checkbox.name]: Checkbox
  },
  metaInfo: {
    title: 'Utah Montessori - Council of Elders',
    meta: [
      { name: 'description', content: 'The ones who started UMC.' }
    ]
  }
}
</script>
<style>
</style>
