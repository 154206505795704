<template>
  <div class="wrapper blog-post">
    <div class="page-header page-header-small">
      <parallax
        class="page-header-image"
        style="background-image: url('img/blog/rich.jpg')"
      ></parallax>
      <div class="content-center">
        <div class="row">
          <div class="col-md-8 ml-auto mr-auto text-center">
            <h2 class="title">A Rich Environment</h2>
            <h4>Duna Strachan, BS AMS MEd (11/12/19)</h4>
          </div>
        </div>
      </div>
    </div>
    <div class="section">
      <div class="container">
        <div class="row">
          <div class="col-md-10 ml-auto mr-auto">
            <h3 class="title">A Rich Environment</h3>
            <p>Soft music plays as students sit in the morning sun, each involved in an academic task suited to their own developmental pace. One is practicing multiplication and division, two are working on a timeline of art history, another is typing up her research on wooly mammoths. There is work on fractions, grammar, spelling and science. Students have the freedom to move ahead once they have mastered a skill, so most are working beyond state and national standards. They have the choice to work inside or outside. They have the choice to move when they want to, to sit at a table or sprawl on the floor. They can take a break and get a snack when they feel hungry or move around if they need to. The room is equipped much like an exploratorium with concrete examples of everything they will learn and a multitude of exercises for practicing each new concept until it is mastered. There are plants and animals to care for, a good view of the seasons as they change and plenty of natural light. There are classical works of art on the walls to ponder, an array of media to experiment with and songs, games, dance, music and theater to experience. There is a science lab to explore, hikes, field trips, camping & river trips and community events to plan for. It is a beautiful morning in the Wasatch Class and the day has just begun.</p>
            <p>Over the course of the school year the students at all levels, from infants to 6th grade, will touch on virtually every topic in the universe, each at their own individual level. In fact, the curriculum is designed to help children identify with their place in the universe – an idea that came far ahead of its time when Dr. Montessori presented it over a century ago and it continues to be a cutting edge concept in education along with her ideas for experiential and mastery learning and educating the senses.</p>
            <p>Contrast this scene with one from a traditional school where students sit at desks in a room devoid of plants, pets, art, flowers, music and meaningful things to do. There isn’t the sense of community that comes from the daily routines of students preparing snack for each other, helping younger students and working in the garden together. There isn’t the sense of responsibility that comes from choosing what to work on each day, what tools to use and whether to collaborate or work independently, developing one’s own intrinsic motivation. There isn’t the sense of unlimited creativity that comes when the class plans their next field trip, how to finance it and what they will do to make it happen. And there isn’t the sense of self-worth that comes from setting goals and knowing that the teacher is someone who knows you well and can help you make almost anything happen.</p>
            <p>Zoologists have long seen that the quality of an animals’ environment directly affects the animal’s development. Zoos make a point of adding interesting features to animal habitats and hiding their food to give them something to do. With this knowledge, why are our traditional schools still such stark places with only a few posters or lists of rules on the walls, where children are expected to sit in desks and wait to be told what to do? This was the factory model developed in the early 1900’s to produce factory workers for our growing nation. The factory model still works and produces a pretty good product. But in order to prepare them for lifelong success in today’s world, our students need the freedom to follow their interests, learning from a rich environment of real life experiences in every possible area of academic development.</p>
            <p>An article from the October 2019 issue of Scientific American (Guernsey, 2019) pointed out that educational programs offering individual choice and collaborative activities, such as are offered in Montessori programs, prepare children for greater success for years afterward. Jeff Bezos’ offer of funding for programs that offer the kinds of experiences he had in his Montessori school emphasizes current research findings: choice-making and interaction give children advantages in executive function and oral language skills which lead to greater success throughout life. Dr. Montessori’s inspiration in education was to allow children to educate themselves by furnishing classrooms with a myriad of rich experiences which are accessible to them at all times. She found that children working in what she called “the prepared environment” get plenty of practice in making choices, mastering activities and completing the details of each project, building what educators now term executive function skills. Montessori saw these skills as preparing the child not only for school, but for life. 114 years after her development of the Montessori Method it is appropriate that a Montessori child, Jeff Bezos, reminds us that these details in education are important to the success of all children.</p>
            <p>Bezos joins other celebrity Montessori children such as Katherine Graham of the Washington Post, author Gabriel Marquez, Princes Harry and William, Helen Keller, Jackie Onassis, Anne Frank, Beyonce, Taylor Swift, Dakota Fanning, Sergey Brin & Larry Page in crediting their Montessori beginnings to their success. Recent research from Lillard (2007) and Rathunde (2014) back up these claims with data showing that Montessori students learn more, retain the information longer and have more positive relationships than children from traditional school programs. The details of the rich Montessori environment allow the child to absorb information with all senses and entice development in all directions, allowing the whole child to develop to the highest potential.</p>
            <p>“The environment must be rich in motives which lend interest to activity and invite the child to conduct his own experiences.” (Montessori, 1984)</p>
            <h3 class="title">References</h3>
            <p>Guernsey, L. (2019). Smart start. Scientific American, (October, 2019), 68-73.</p>
            <p>Lillard, A. (2007). The science behind the genius. New York: Oxford University Press.</p>
            <p>Montessori, M. (1984). The absorbent mind. India: Kalakshetra Publications.</p>
            <p>Rathunde, K. (2014). Understanding optimal school experience: contributions from Montessori education. In Engaging Youth in Schools: Empirically Based Models to Guide Future Innovations. New York: Teachers College Press.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Button, Checkbox } from '@/components';

export default {
  name: 'rich-environment',
  bodyClass: 'blog-post',
  components: {
    [Button.name]: Button,
    [Checkbox.name]: Checkbox
  },
  metaInfo: {
    title: 'Utah Montessori - Rich Environment',
    meta: [
      { name: 'description', content: 'Soft music plays as students sit in the morning sun, each involved in an academic task suited to their own developmental pace. One is practicing multiplication and division, two are working on a timeline of art history, another is typing up her research on wooly mammoths.' }
    ]
  }
}
</script>
<style>
</style>
