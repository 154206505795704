<template>
  <div class="wrapper presentation-page">
    <!-- START PARALLAX -->
    <div class="page-header clear-filter">
      <div class="rellax-header rellax-header-buildings" data-rellax-speed="0">
        <parallax
          class="page-header-image"
          style="opacity: 0.8; background-image: url('img/home/utah.jpg')"
        >
        </parallax>
      </div>
      <div class="rellax-text-container rellax-text">
        <h1 class="h1-seo" data-rellax-speed="-2">UMC</h1>
      </div>
      <h3 class="h3-description rellax-text" data-rellax-speed="-1">
        A Montessori Vision of the Past, Present, and Future.
      </h3>
    </div>
    <!-- END PARALLAX -->

    <!-- START SPONSER AREA -->
    <div class="section section-pricing">
      <div class="container">
        <div class="our-clients">
          <div class="container">
            <div class="row">
              <div class="col-md-8 ml-auto mr-auto text-center">
                <h2 class="title">Sponsors</h2>
              </div>
              <div class="col-md-8 ml-auto mr-auto">
                <p class="mb-5 text-center">
                  If you would like to become a Sponsor and have your school
                  logo and website link listed below email
                  bruce@soaringwings.org.
                </p>
              </div>
            </div>
            <div class="row">
              <div class="col-md-3">
                <a href="https://mydancingmoose.com/" target="_blank"
                  ><img
                    v-lazy="'img/sponsors/dancingmoose.png'"
                    alt="Dancing Moose"
                /></a>
              </div>
              <div class="col-md-3">
                <a href="https://www.elizabethacademy.com/" target="_blank"
                  ><img v-lazy="'img/ea.jpg'" alt="Elizabeth Academy"
                /></a>
              </div>
              <div class="col-md-2">
                <a href="http://ourjourney.org/" target="_blank"
                  ><img
                    v-lazy="'img/sponsors/ourjourneymontessori.png'"
                    alt="Our Journey Montessori School"
                /></a>
              </div>
              <div class="col-md-4">
                <a href="https://soaringwings.org/" target="_blank"
                  ><img
                    v-lazy="'img/sponsors/soaringwings.gif'"
                    alt="Soaring Wings International Montessori School"
                    class="swims"
                /></a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- END SPONSER AREA -->

    <!-- START INFO UMC -->
    <div class="section section-components" data-background-color="dark-red">
      <div class="container">
        <div class="row">
          <div class="col-md-10 ml-auto mr-auto">
            <h2 class="text-center title">Utah Montessori Council</h2>
            <p class="text-center">
              In the spring of 2003 while attending the annual NCME conference
              in San Diego, Duna Strachan, Director of Soaring Wings Montessori
              School in Park City, was struck by the fact that of the few Utahns
              who attended the national conference many were hard-working
              teachers who had largely paid their own way. In order to provide a
              professional, affordable, accessible conference experience for
              every Montessori director, teacher, assistant, staff member and
              parent in Utah, the founding conference of the Utah Montessori
              Council was held at SWMS on January 31, 2004.
            </p>
            <p class="text-center">
              Heads of every major school enthusiastically stepped forward to
              lend their support. Founding officers were elected and the mission
              of establishing a supportive base and inclusive networking
              opportunities for all members regardless of school affiliation was
              laid down.
            </p>
            <p class="text-center">
              In ensuing years the annual conference site has shifted from
              school to school and attendance has grown each year. With
              volunteer efforts of presenters and members and generous donations
              of time and funds from host schools the costs of putting on the
              conference have been kept to a minimum so that registration fees
              can be kept affordable for all.
            </p>
            <p class="text-center">
              Membership is open to all; you don’t need to be a Montessorian or
              a Montessori school. All you have to do is complete the one page
              application and agree to abide by the Code of Ethics and it’s
              free!
            </p>
            <div class="text-center">
              <router-link
                tag="a"
                class="btn btn-primary"
                :to="{ name: 'member' }"
              >
                <strong>Become a Member</strong>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- END INFO UMC -->
  </div>
</template>

<script>
import { Card, InfoSection } from "@/components";
import { Carousel, CarouselItem, Tooltip } from "element-ui";

export default {
  name: "home",
  bodyClass: "home-page",
  components: {
    Card,
    InfoSection,
    [Carousel.name]: Carousel,
    [CarouselItem.name]: CarouselItem,
    [Tooltip.name]: Tooltip,
  },
  metaInfo: {
    title: "Utah Montessori",
    meta: [
      {
        name: "description",
        content: "A Montessori Vision of the Past, Present, and Future.",
      },
    ],
  },
};
</script>

<style scoped lang="scss">
.github-buttons {
  display: flex;
  align-content: center;
}

.swims {
  width: 100% !important;
  height: 100px !important;
}
</style>
