<template>
  <div class="wrapper duna-strachan">
    <div class="page-header page-header-small">
      <parallax
        class="page-header-image"
        style="background-image: url('img/home/utah.jpg')"
      ></parallax>
      <div class="content-center">
        <div class="row">
          <div class="col-md-8 ml-auto mr-auto text-center">
            <h2 class="title">Nancy Lindeman, MS</h2>
            <h4>nlindeman@westminstercollege.edu</h4>
          </div>
        </div>
      </div>
    </div>
    <div class="section">
      <div class="container">
        <div class="row">
          <div class="col-md-12 ml-auto mr-auto">
            <h3 class="title">About Nancy</h3>

            <p>Nancy Lindeman has been a special educator since 1978. She recently retired as the program director from the Institute for Montessori Innovation at Westminster College. She is EC, El I-II certified and holds a Montessori Administrators certificate. Nancy has founded and directed a Utah public Montessori charter school and two private Montessori schools. She currently serves on the AMS board of directors.</p>
            <img
              slot="avatar"
              class="img img-raised"
              src="img/elders/nancy-full.jpeg"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Card, Button } from '@/components';

export default {
  name: 'duna-strachan',
  bodyClass: 'duna-strachan',
  components: {
    Card,
    [Button.name]: Button
  },
  metaInfo: {
    title: 'Utah Montessori - Duna Strachan',
    meta: [
      { name: 'description', content: 'Duna founded Soaring Wings in 1987. She received a BS in Wildlife Biology from Arizona State University and was in the midst of a Master’s degree in Environmental Zoology when parenthood suddenly drew her attention to the world of Montessori.' }
    ]
  }
}
</script>
<style>
.mt-0 {
  margin-top: 0 !important;
}
</style>
