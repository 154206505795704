<template>
  <div class="wrapper blog-post">
    <div class="page-header page-header-small">
      <parallax
        class="page-header-image"
        style="background-image: url('img/home/utah.jpg')"
      ></parallax>
      <div class="content-center">
        <div class="row">
          <div class="col-md-8 ml-auto mr-auto text-center">
            <h2 class="title">Blog Posts</h2>
            <h4>What are we up to?</h4>
          </div>
        </div>
      </div>
    </div>
    <div class="cd-section" id="blogs">
      <div class="blogs-4" id="blogs-4">
        <div class="container">
          <div class="row">
            <div class="col-md-8 ml-auto mr-auto">
              <h2 class="title">Latest Posts</h2>
              <br />
              <card
                type="blog"
                plain
                v-for="(blog, index) in blogs"
                :key="index"
              >
                <div class="card-image">
                  <router-link tag="a" :to="blog.link">
                    <img class="img img-raised rounded" :src="blog.img"
                  /></router-link>
                </div>
                <h3 class="card-title">
                  <router-link tag="a" :to="blog.link"
                    >{{ blog.name }}
                  </router-link>
                </h3>
                <h6 class="category text-info">{{ blog.author }}</h6>
                <h5 class="card-description">
                  {{ blog.text }}
                </h5>
                <div v-if="blog.toLink === false">
                  <router-link tag="a" class="btn btn-primary" :to="blog.link"
                    ><strong>Read More</strong>
                  </router-link>
                </div>
                <div v-else>
                  <a :href="blog.link" target="_blank" class="btn btn-primary"
                    ><strong>Read More</strong></a
                  >
                </div>
              </card>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  Card,
  Button,
  Comment,
  Badge,
  InfoSection,
  Checkbox,
} from "@/components";

export default {
  name: "blog-post",
  bodyClass: "blog-post",
  data() {
    return {
      blogs: [
        {
          name:
            "Spending Just a Few Years in Montessori Education Predicts Well-being in Adulthood",
          author: "Jane Kelly",
          text:
            "Be it in preschool or beyond, new research from the University of Virginia has found that people who had at least two years of Montessori education report a sense of well-being in adulthood...",
          link:
            "https://soaringwings.org/2022/01/spending-just-a-few-years-in-montessori-education-predicts-well-being-in-adulthood/",
          img: "img/blog/rich.jpg",
          toLink: true,
        },
        {
          name: "Education for Peace",
          author: "Duna Strachan",
          text:
            "In every Montessori classroom around the planet you will find globes, maps and flags of the world. And in a Montessori class we take the study of geography many steps further. There are also rattles and baskets from each culture in the infant classes, dolls and native textiles to try on in the toddler and early childhood classes, research and expeditionary experiences in the elementary, middle school and high school classes...",
          link: { name: "educationforpeace" },
          img: "img/blog/rich.jpg",
          toLink: false,
        },
        {
          name: "It Is What It Is?",
          author: "Duna Strachan",
          text:
            "We recently began a review of language development within the faculty. With young children we pay much attention to speaking clearly, succinctly and positively. We avoid baby talk or long explanations. We just tell them directly what they need to know...",
          link: { name: "itiswhatitis" },
          img: "img/blog/rich.jpg",
          toLink: false,
        },
        {
          name: "The Jewel of a School",
          author:
            "Infant/Toddler Faculty at Center for Guided Montessori Studies",
          text:
            "An infant kicks excitedly at a mobile hanging overhead. A seated child reaches into a treasure basket to grasp a soft bristled brush. A toddler tries with all her might to lift a pitcher to pour her own juice... while another kneads dough for baking daily bread. These are a few of the things you might see if you peek into a Montessori infant or toddler environment...",
          link: { name: "jewel-school" },
          img: "img/blog/rich.jpg",
          toLink: false,
        },
        {
          name: "Our Pandemic Experience",
          author: "Duna Strachan",
          text:
            "The sunshine filters in across the glistening beads as a student counts them carefully. Another student is on the patio applying strokes of paint to a new design. Two more work in the garden, and several sit at separate tables, intent on their work. It is a typical day in an early childhood class, except that these children are wearing face masks. It is July 2020 and we are in the depths of the COVID19 Pandemic.",
          link: { name: "our-pandemic-experience" },
          img: "img/blog/rich.jpg",
          toLink: false,
        },
        {
          name: "A Rich Environment",
          author: "Duna Strachan",
          text:
            "Soft music plays as students sit in the morning sun, each involved in an academic task suited to their own developmental pace. One is practicing multiplication and division, two are working on a timeline of art history, another is typing up her research on wooly mammoths. There is work on fractions, grammar, spelling and science....",
          link: { name: "rich-environment" },
          img: "img/blog/rich.jpg",
          toLink: false,
        },
      ],
    };
  },
  components: {
    Card,
    InfoSection,
    Badge,
    Comment,
    [Button.name]: Button,
    [Checkbox.name]: Checkbox,
  },
  metaInfo: {
    title: "Utah Montessori - Blog",
    meta: [{ name: "description", content: "What are we up to?" }],
  },
};
</script>
<style></style>
