<template>
  <div class="wrapper blog-post">
    <div class="page-header page-header-small">
      <parallax
        class="page-header-image"
        style="background-image: url('img/home/utah.jpg')"
      ></parallax>
      <div class="content-center">
        <div class="row">
          <div class="col-md-12 ml-auto mr-auto text-center">
            <h2 class="title">Montessori Teacher Training Programs</h2>
          </div>
        </div>
      </div>
    </div>
    <div class="section">
      <div class="container">
        <div class="row">
          <div class="col-md-10 ml-auto mr-auto">
            <h3 class="title">Early Childhood</h3>
            <p>
            <ul>
              <li> <a
                  href="https://www.ringsofknowledge.com/"
                  target="_blank"
                ><strong>Rings of Knowledge</strong></a></li>
            </ul>
            </p>
            <br><br><br>
            <h6><strong><i>* The UMC does not recommend any teacher training program in particular, but we offer this list for your convenience in comparing options.</i></strong></h6>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Card, Button, Comment, Badge, InfoSection, Checkbox } from '@/components';

export default {
  name: 'member',
  bodyClass: 'member',
  components: {
    Card,
    InfoSection,
    Badge,
    Comment,
    [Button.name]: Button,
    [Checkbox.name]: Checkbox
  },
  metaInfo: {
    title: 'Utah Montessori - Montessori Teacher Training Programs',
    meta: [
      { name: 'description', content: 'Trainings that have been used by members.' }
    ]
  }
}
</script>
<style>
</style>
