<template>
  <div class="wrapper contact-us">
    <div class="page-header page-header-small">
      <parallax
        class="page-header-image"
        style="background-image: url('img/home/utah.jpg')"
      ></parallax>
      <div class="content-center">
        <div class="row">
          <div class="col-md-8 ml-auto mr-auto text-center">
            <h2 class="title">Contact Us</h2>
            <h4>Have questions? Give us a call or send us a message.</h4>
          </div>
        </div>
      </div>
    </div>
    <div class="section">
      <div class="container">
        <div class="row">
          <!-- <div class="col-md-5 ml-auto mr-auto">
            <h2 class="title">Send us a message</h2>
            <p class="description">You can contact us with anything related to UMC. We'll get
              in touch with you as soon as possible.
              <br>
              <br>
            </p>
            <form
              name="contact-us"
              method="POST"
              data-netlify="true"
              data-netlify-honeypot="bot-field"
            >
              <input
                type="hidden"
                name="contact-us"
                value="ask-question"
              />
              <label>Your name</label>
              <fg-input
                placeholder="Your Name..."
                v-model="form.firstName"
                addon-left-icon="now-ui-icons users_circle-08"
                name="name"
              >
              </fg-input>

              <label>Email address</label>
              <fg-input
                placeholder="Email Here..."
                v-model="form.email"
                addon-left-icon="now-ui-icons users_circle-08"
                name="email"
              >
              </fg-input>
              <label>Phone</label>

              <fg-input
                placeholder="Number Here..."
                v-model="form.phone"
                addon-left-icon="now-ui-icons tech_mobile"
                name="phone"
              >
              </fg-input>

              <div class="form-group">
                <label>Your message</label>
                <textarea
                  name="message"
                  class="form-control"
                  id="message"
                  rows="6"
                ></textarea>
              </div>
              <div class="submit text-center">
                <button
                  type="submit"
                  class="btn btn-primary"
                ><strong>Submit</strong></button>
              </div>
            </form>
          </div> -->
          <div class="col-md-4 ml-auto mr-auto">
            <info-section
              type="primary"
              icon="now-ui-icons location_pin"
            >
              <h4 class="info-title">Find us at the office</h4>
              <p>
                1580 Old Ranch Road,
                <br>
                Park City, Utah 84098
              </p>
            </info-section>
          </div>
          <div class="col-md-4 ml-auto mr-auto">
            <info-section
              type="primary"
              icon="now-ui-icons tech_mobile"
            >
              <h4 class="info-title">Give us a ring</h4>
              <p> (435) 659-1493
              </p>
            </info-section>
          </div>
          <div class="col-md-4 ml-auto mr-auto">
            <info-section
              type="primary"
              icon="now-ui-icons ui-1_email-85"
            >
              <h4 class="info-title">Send us an email</h4>
              <p> duna@soaringwings.org
              </p>
            </info-section>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Button, InfoSection, FormGroupInput } from '@/components';
export default {
  name: 'contact-us',
  bodyClass: 'contact-us',
  components: {
    InfoSection,
    [Button.name]: Button,
    [FormGroupInput.name]: FormGroupInput,
  },

  data () {
    return {
      form: {
        firstName: '',
        email: '',
        phone: ''
      }
    }
  },
  metaInfo: {
    title: 'Utah Montessori - Contact Us',
    meta: [
      { name: 'description', content: 'Have a question? Send us a message!' }
    ]
  }
}
</script>
<style>
</style>
