<template>
  <footer
    class="footer"
    :class="{[`footer-${type}`]: type}"
    :data-background-color="backgroundColor"
  >
    <div class="container">
      <nav>
        <ul>
          <li>
            <router-link
              tag="a"
              :to="{name: 'home'}"
            >Home</router-link>
          </li>
          <li>
            <router-link
              tag="a"
              :to="{name: 'blog'}"
            >Blog</router-link>
          </li>
          <li>
            <router-link
              tag="a"
              :to="{name: 'contact'}"
            >Contact</router-link>
          </li>
        </ul>
      </nav>
      <div class="copyright">
        &copy; {{year}}, Utah Montessori
      </div>
    </div>
  </footer>
</template>
<script>
export default {
  props: {
    backgroundColor: String,
    type: String
  },
  data () {
    return {
      year: new Date().getFullYear()
    }
  }
}
</script>
<style>
</style>
