import Vue from "vue";
import Router from "vue-router";
import BecomeAMember from "./pages/AboutUs/BecomeAMember.vue";
import Blog from "./pages/Blog.vue";
import Certifications from "./pages/Certifications.vue";
import Consultation from "./pages/Consultation.vue";
import ContactUs from "./pages/ContactUs.vue";
import DunaStrachan from "./pages/Elders/DunaStrachan.vue";
import NancyLindeman from "./pages/Elders/NancyLindeman.vue";
import CouncilElders from "./pages/AboutUs/CouncilElders.vue";
import History from "./pages/AboutUs/History.vue";
import MainNavbar from "./layout/MainNavbar.vue";
import MainFooter from "./layout/MainFooter.vue";
import MemberSchools from "./pages/AboutUs/MemberSchools.vue";
import Home from "./pages/Home.vue";
import RichEnvironment from "./pages/Posts/RichEnvironment.vue";
import OurPandemicExperience from "./pages/Posts/OurPandemicExperience.vue";
import JewelSchool from "./pages/Posts/JewelSchool.vue";
import ItIsWhatItIs from "./pages/Posts/ItIsWhatItIs.vue";
import EducationForPeace from "./pages/Posts/EducationForPeace.vue";
import Resources from "./pages/AboutUs/Resources.vue";
import CallForPresenters from "./pages/Events/CallForPresenters.vue";
import FallWorkshop from "./pages/Events/FallWorkshop.vue";
import UMCConference from "./pages/Events/UMCConference.vue";
import AdminRetreat from "./pages/Events/AdminRetreat.vue";
import UMCInfo from "./pages/Events/UMCInfo.vue";
import FallWorkshopInfo from "./pages/Events/FallWorkshopInfo.vue";
import Training from "./pages/AboutUs/Training.vue";

Vue.use(Router);

const routes = [
  {
    path: "/",
    name: "home",
    components: { default: Home, header: MainNavbar, footer: MainFooter },
    props: { header: { colorOnScroll: 500 } },
  },
  {
    path: "/become-a-member",
    name: "member",
    components: {
      default: BecomeAMember,
      header: MainNavbar,
      footer: MainFooter,
    },
    props: { header: { colorOnScroll: 450 } },
  },
  {
    path: "/training",
    name: "training",
    components: { default: Training, header: MainNavbar, footer: MainFooter },
    props: { header: { colorOnScroll: 450 } },
  },
  {
    path: "/council-elders",
    name: "council-elders",
    components: {
      default: CouncilElders,
      header: MainNavbar,
      footer: MainFooter,
    },
    props: { header: { colorOnScroll: 450 } },
  },
  {
    path: "/history",
    name: "history",
    components: { default: History, header: MainNavbar, footer: MainFooter },
    props: { header: { colorOnScroll: 450 } },
  },
  {
    path: "/member-schools",
    name: "member-schools",
    components: {
      default: MemberSchools,
      header: MainNavbar,
      footer: MainFooter,
    },
    props: { header: { colorOnScroll: 450 } },
  },
  {
    path: "/resources",
    name: "resources",
    components: {
      default: Resources,
      header: MainNavbar,
      footer: MainFooter,
    },
    props: { header: { colorOnScroll: 450 } },
  },
  {
    path: "/blog",
    name: "blog",
    components: { default: Blog, header: MainNavbar, footer: MainFooter },
    props: { header: { colorOnScroll: 500 } },
  },
  {
    path: "/certifications",
    name: "certifications",
    components: {
      default: Certifications,
      header: MainNavbar,
      footer: MainFooter,
    },
    props: { header: { colorOnScroll: 450 } },
  },
  {
    path: "/consultation",
    name: "consultation",
    components: {
      default: Consultation,
      header: MainNavbar,
      footer: MainFooter,
    },
    props: { header: { colorOnScroll: 450 } },
  },
  {
    path: "/duna-strachan",
    name: "duna-strachan",
    components: {
      default: DunaStrachan,
      header: MainNavbar,
      footer: MainFooter,
    },
    props: { header: { colorOnScroll: 200 } },
  },
  {
    path: "/nancy-lindeman",
    name: "nancy-lindeman",
    components: {
      default: NancyLindeman,
      header: MainNavbar,
      footer: MainFooter,
    },
    props: { header: { colorOnScroll: 200 } },
  },
  {
    path: "/contact-us",
    name: "contact",
    components: {
      default: ContactUs,
      header: MainNavbar,
      footer: MainFooter,
    },
    props: { header: { colorOnScroll: 450 } },
  },
  {
    path: "/events",
    name: "events",
    redirect: "/",
  },
  {
    path: "/fall-workshop",
    name: "fall-workshop",
    components: {
      default: FallWorkshop,
      header: MainNavbar,
      footer: MainFooter,
    },
    props: { header: { colorOnScroll: 450 } },
  },
  {
    path: "/administrator-retreat",
    name: "administrator-retreat",
    redirect: "/",
  },
  {
    path: "/umc-conference",
    name: "umc-conference",
    components: {
      default: UMCConference,
      header: MainNavbar,
      footer: MainFooter,
    },
    props: { header: { colorOnScroll: 450 } },
  },
  {
    path: "/admin-retreat",
    name: "admin-retreat",
    components: {
      default: AdminRetreat,
      header: MainNavbar,
      footer: MainFooter,
    },
    props: { header: { colorOnScroll: 450 } },
  },
  {
    path: "/umc-info",
    name: "umc-info",
    components: { default: UMCInfo, header: MainNavbar, footer: MainFooter },
    props: { header: { colorOnScroll: 450 } },
  },
  {
    path: "/fall-workshop-info",
    name: "fall-workshop-info",
    components: {
      default: FallWorkshopInfo,
      header: MainNavbar,
      footer: MainFooter,
    },
    props: { header: { colorOnScroll: 450 } },
  },
  {
    path: "/call-for-presenters",
    name: "call-for-presenters",
    components: {
      default: CallForPresenters,
      header: MainNavbar,
      footer: MainFooter,
    },
    props: { header: { colorOnScroll: 450 } },
  },
  {
    path: "*",
    redirect: "/",
  },
  {
    path: "/#/",
    redirect: "/",
  },
  {
    path: "/blog/rich-environment",
    name: "rich-environment",
    components: {
      default: RichEnvironment,
      header: MainNavbar,
      footer: MainFooter,
    },
    props: { header: { colorOnScroll: 500 } },
  },
  {
    path: "/blog/our-pandemic-experience",
    name: "our-pandemic-experience",
    components: {
      default: OurPandemicExperience,
      header: MainNavbar,
      footer: MainFooter,
    },
    props: { header: { colorOnScroll: 500 } },
  },
  {
    path: "/blog/jewel-school",
    name: "jewel-school",
    components: {
      default: JewelSchool,
      header: MainNavbar,
      footer: MainFooter,
    },
    props: { header: { colorOnScroll: 500 } },
  },
  {
    path: "/blog/itiswhatitis",
    name: "itiswhatitis",
    components: {
      default: ItIsWhatItIs,
      header: MainNavbar,
      footer: MainFooter,
    },
    props: { header: { colorOnScroll: 500 } },
  },
  {
    path: "/blog/educationforpeace",
    name: "educationforpeace",
    components: {
      default: EducationForPeace,
      header: MainNavbar,
      footer: MainFooter,
    },
    props: { header: { colorOnScroll: 500 } },
  },
];

const router = new Router({
  mode: "history",
  hash: false,
  routes,
  scrollBehavior: (to, from, savedPosition) => {
    if (to.hash) {
      return { selector: to.hash };
    } else {
      return { x: 0, y: 0 };
    }
  },
});

router.beforeEach((to, from, next) => {
  // Redirect if fullPath begins with a hash (ignore hashes later in path)
  if (to.fullPath.substr(0, 2) === "/#") {
    const path = to.fullPath.substr(2);
    next(path);
    return;
  }
  next();
});

export default router;
