<template>
  <div class="wrapper fall-workshop">
    <div class="page-header page-header-small">
      <parallax
        class="page-header-image"
        style="background-image: url('img/beach.jpg')"
      ></parallax>
      <div class="content-center">
        <div class="row">
          <div class="col-md-8 ml-auto mr-auto text-center">
            <h2 class="title">UMC Administrators' Retreat</h2>
            <h4>
              Friday - Sunday, October 25th-27th, 2024<br />Depot Bay, Oregon
            </h4>
          </div>
        </div>
      </div>
    </div>
    <div class="section">
      <div class="container">
        <h3 class="title text-center">Primordial Purpose</h3>
        <h5>
          Join us for walking the beach, looking for whales, fossils, shells,
          new knowledge and our own sense of being.
        </h5>
        <a
          target="_blank"
          class="btn btn-primary btn-lg text-white"
          href="downloads/Primordial Purpose.png"
          ><strong>Download Flyer</strong></a
        >
        <hr />
        <!-- <div class="row">
          <div class="col-md-12 ml-auto mr-auto">
            <h3 class="title">Schedule</h3>
            <h4>
              <b><u>Friday</u></b>
            </h4>
            <p>
              <b>5:00 PM:</b> Welcome Dinner and Opening Ceremony with Nancy
              Lindeman
            </p>
            <h4>
              <b><u>Saturday</u></b>
            </h4>
            <p><b>8:00 AM:</b> Breakfast</p>
            <p><b>9:00 AM:</b> Keynote</p>
            <p><b>12:00 PM:</b> Lunch</p>
            <p>
              <b>1:00 PM:</b> Self-directed Hiking, Jeeping, or Crafting with
              Natural Materials
            </p>
            <p><b>3:00 PM:</b> Ranch House Cooking</p>
            <p><b>6:00 PM:</b> Dinner and Campfire Sing-along</p>
            <h4>
              <b><u>Sunday</u></b>
            </h4>
            <p><b>8:00 AM:</b> Breakfast</p>
            <p><b>9:00 AM:</b> Closing Ceremony with Lois Bobo</p>
          </div>
        </div> -->
        <div class="row">
          <div class="col-md-12 ml-auto mr-auto">
            <h3 class="title">Registration Information</h3>
            <p>
              In order to secure enough lodging in advance, we ask for
              $200/person by <strong>March 29th</strong>.
            </p>
            <a
              target="_blank"
              class="btn btn-primary btn-lg text-white"
              href="https://form.jotform.com/240465751625155"
              ><strong>Register Now!</strong></a
            ><br /><br />
            <p>
              You will provide your own transportation.<br />
              Airfare is $120 - $300 roundtrip from SLC to Eugene or PDX,
              depending on carriers, plus car rental.
            </p>
          </div>
        </div>
        <hr />
        <div class="row">
          <div class="col-md-12 ml-auto mr-auto">
            <p>
              Sorry, but the UMC cannot provide refunds, credits, discounts or
              transfers.
            </p>
            <p>
              Registration is limited to members of the UMC. Go to
              utahmontessori.org to complete the membership form and return it
              to duna@soaringwings.org. Membership will include you in
              notifications of all upcoming events.
            </p>
          </div>
        </div>
        <hr />
        <a
          target="_blank"
          class="btn btn-primary btn-lg text-white"
          href="https://docs.google.com/forms/d/e/1FAIpQLScx8A9kCaxNt4bElbEz3_pEQhIJWaHNsRM-ItqwAsMIIU8l5g/viewform?usp=sf_link
"
          ><strong>Submit Elders Nomination for UMC</strong></a
        >
      </div>
    </div>
  </div>
</template>
<script>
import { Button, InfoSection, FormGroupInput } from "@/components";

export default {
  name: "fall-workshop",
  bodyClass: "fall-workshop",
  components: {
    InfoSection,
    [Button.name]: Button,
    [FormGroupInput.name]: FormGroupInput,
  },

  data() {
    return {
      form: {
        firstName: "",
        email: "",
        phone: "",
      },
    };
  },
  metaInfo: {
    title: "Utah Montessori - Fall Workshop",
    meta: [
      { name: "description", content: "Sign up for the UMC Fall Workshop." },
    ],
  },
};
</script>
<style></style>
